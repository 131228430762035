@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	activity
/--------------------------------------------------------------------*/
// activity-intro
.activity-intro {
	position: relative;
	padding-top: clamp(2rem, 3vw, 3rem);
	padding-bottom: clamp(6em, 9vw, 9rem);
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		width: 100%;
		height: 50%;
		background: $bg_c1;
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include m-a;
	}
}

.activity-intro {
	&__txt {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
	}
	&__list {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
			gap: 2em 2%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			row-gap: 1.5em;
			max-width: 480px;
			@include m-a;
		}
	}
}

// activity-transition
.activity-transition {
	padding-top: clamp(4rem, 6vw, 6rem);
	padding-bottom: clamp(6em, 9vw, 9rem);
	background: $bg_c3;
	&__inner {
		max-width: 1000px;
		@include m-a;
	}
	&__list {
		row-gap: clamp(2rem,5vw,5rem);
		@include flex-column;
	}
	&__img {
		figcaption {
			margin-top: 1em;
		}
	}
}

// activity-support
.activity-support {
	padding-top: clamp(4rem, 6vw, 6rem);
	&__inner {
		max-width: 1200px;
		@include m-a;
	}
	&__list {
		display: grid;
		@include media-breakpoint-up(sm) {
			grid-template-columns: 1fr 1fr;
			gap:2rem 4%;
		}
		@include media-breakpoint-down(xs) {
			grid-template-columns: 1fr;
			row-gap: clamp(2rem,5vw,5rem);
		}
	}
	&__img {
		figcaption {
			margin-top: 1em;
		}
	}
}