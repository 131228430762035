@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/

.blog-intro {
	padding: clamp(1.5rem, 2vw, 2rem) 5%;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	background: $p-gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(2rem, 3vw, 3rem) 8%;
		text-align: center;
	}
}

.contents-flex {
	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: space-between;
	}
}

.contents-left {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: calc(96% - 320px);
	}
}


/*-------------------------------------------------------/
//Easy Table of Contents
--------------------------------------------------------*/

#ez-toc-container {
	border-width: 1px;
	border-radius: 0;
	margin-bottom: clamp(3em,6vw,6em) !important;
	border-color: $m-gray !important;
}

/* 目次タイトルの背景色と余白調整 */
#ez-toc-container .ez-toc-title-container,
#ez-toc-container {
	display: block;
	background: $main_c;
	margin: 0;
	padding: .75em 2em .75em 1.5em;
}
/* 目次タイトルの余白とカラー */
div#ez-toc-container .ez-toc-title {
	display: block;
	color: $white !important;
}

/* 目次内の上下余白調整 */
#ez-toc-container .ez-toc-list {
	padding: 1em 1.5em;
}

.ez-toc-js-icon-con {
	border: none !important;
}

/* 見出しの下線 */
#ez-toc-container .ez-toc-list li {
	display: block;
	border-bottom: 1px dashed $m-gray;
	a {
		display: block;
		line-height: 1.5;
		padding: 0.5em 0;
	}
}
#ez-toc-container .ez-toc-list li:last-child {
	border: 0;
}

/* 目次開閉ボタン位置調整 */
.ez-toc-title-toggle {
	position: absolute;
	top: .65em;
	right:2.5%;
}

/* 目次開閉ボタンデザイン */
#ez-toc-container .ez-toc-js-icon-con {
	border: none;
	background: $p-gray;
	border-radius: 0;
	width: 33px;
}


/* スマホ向けのサイズと位置調整 */
@media (max-width: 500px) {
	/* 目次タイトル */
	div#ez-toc-container .ez-toc-title {
		font-size: 15px;
		text-align: center;
		padding-left: initial;
		padding: 5px 0;
	}
	/* 開閉ボタンの位置 */
}

.wp-block-heading .ez-toc-section {
	padding-top:calc(1em + $header-hight_sp);
	margin-top:calc(-1em - $header-hight_sp);
	@include media-breakpoint-up(xl) {
		padding-top:4em;
		margin-top:-4em;
	}
}