@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	reports
/--------------------------------------------------------------------*/

.reports-list {
	padding-top: clamp(2em, 3vw, 3rem);
}

.report-item {
	&__ttl {
		text-align: center;
		padding-bottom: 0.5em;
		margin-bottom: 0.75em;
		border-bottom: 1px solid $m-gray;
		font-size: 1.25em;
		@include f-w(700);
	}
	&__pdf {
		a {
			padding: 1.25em 4% 1.25em 4.5em;
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			color: $txt_c;
			background: $bg_c3;
			@include transition;
			@include line-h(1.4);
			@include dec-none;
			@include f-w(500);
			@include icon(pdf);
			&::before {
				position: absolute;
				left: 1.25em;
				top: 0.8em;
				font-size: 1.6em;
				color: $main_c;
				@include transition;
			}
			&:hover {
				background: $hover_c;
				color: $white;
				&::before {
					color: $white;
				}
			}
		}
	}
}
