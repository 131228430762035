@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/

.aside-cont {
	&.is-sticky {
		@include media-breakpoint-up(lg) {
			position: sticky;
			top: 120px;
			left: 0;
		}
	}
	&__inner {
		row-gap: clamp(2rem, 4vw, 4rem);
		@include flex-column;
	}
}

.aside-box {
	&__ttl {
		padding: 0.3rem 0 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid $m-gray;
		@include f-w(500);
		font-size: 1.25em;
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.14, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
	&__list {
		&.is-category {
			@include flex-column;
			li {
				border-bottom: 1px solid $l-gray;
				a {
					color: $txt_c;
					@include dec-none;
					display: flex;
					column-gap: 0.5em;
					padding: 0.75em 0.5em 0.75em 0.2em;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: -1px;
						width: 0;
						height: 1px;
						@include transition;
						background: $main_c;
					}
					&:hover {
						&::before {
							width: 100%;
						}
					}
					.is-circle {
						padding-top: 0.5em;
						font-size: 0.7em;
						text-decoration: none !important;
					}
					.is-text {
						@include f-w(500);
					}
				}
			}
		}
	}
}

.wp-block-categories-list,
.wp-block-archives-list,
.wp-block-latest-posts__list {
	li {
		border-bottom: 1px solid $l-gray;
		a {
			color: $txt_c;
			@include dec-none;
			display: block;
			padding: 0.75em 0.5em 0.75em 0;
			position: relative;
			@include media-breakpoint-up(lg) {
				font-size: 0.94em;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: -1px;
				width: 0;
				height: 1px;
				@include transition;
				background: $main_c;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}
}

.tag-cloud-link {
	font-size: 1rem !important;
	color: $txt_c;
	padding-left: 1.5rem;
	margin-right: 0.5rem;
	padding-bottom: 0.3em;
	position: relative;
	@include icon(tag, before);
	@include dec-none;
	@include transition;
	&::before {
		position: absolute;
		top: 0.6em;
		left: 0.2rem;
		font-size: 0.875em;
		color: $gray;
		@include dec-none;
		@include transition;
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 1px;
		background: $main_c;
		@include transition;
	}
	&:hover {
		color: $main_c;
		&::before {
			color: $main_c;
		}
		&::after {
			width: 100%;
		}
	}
}

/* calendar
------------------------------------*/
#wp-calendar {
	border-collapse: separate;
	width: 100%;
}
.calendar_wrap {
	padding: 0.5rem 0.75rem;
	border: 1px solid $m-gray;
}
.wp-calendar {
	&-table {
		//テーブル
		margin-bottom: 0.75rem;
		caption {
			padding: 0.3rem 2%;
			@include f-w(500);
		}
		& thead {
			th {
				text-align: center;
				padding: 6px;
				width: auto;
				color: $gray;
				@include f-w(500);
			}
		}
		td {
			text-align: center;
			background: $p-gray;
			line-height: 2;
			border: 2px solid $white;
			font-size: 0.94em;
			& a {
				background-color: $main_c;
				color: $white;
				@include transition;
				display: block;
				@include dec-none;
				&:hover {
					background-color: $sub_c;
				}
			}
			&.pad {
				background: transparent;
			}
			&#today {
				background: $gray;
				color: $white;
			}
		}
	}
	&-nav {
		@include flex-between;
		width: 100%;
		padding-bottom: 0.3rem;
		a {
			display: block;
			color: $txt_c;
			padding: 0.2em 1rem;
			background-color: $p-gray;
			@include dec-none;
			@include transition;
			&:hover {
				background-color: $main_c;
				color: $white;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
	position: relative;
	width: 100%;
	border: 1px solid $m-gray !important;
	padding: 0 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wp-block-search__input {
	height: 44px;
	padding: 0 10px !important;
	outline: 0;
	background: $white;
	appearance: none;
	border: none;
	width: calc(100% - 50px);
}

.wp-block-search__button {
	margin: 0;
	height: 44px !important;
	width: 50px !important;
	padding: 2px !important;
	background: $main_c !important;
	font-size: 18px !important;
	vertical-align: bottom !important;
	color: $white !important;
	@include transition;
	border: none !important;
	text-align: center;
	cursor: pointer !important;
	&:hover {
		color: $white !important;
		background-color: $hover_c !important;
	}
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives-dropdown {
	position: relative;
	margin-top: 1em;
	@include icon(arrow1_bottom, after);
	&::after {
		position: absolute;
		top: .75em;
		right: 0.5em;
		color: $gray;
		@include line-h(1);
		@include f-size(21);
	}
	& > label {
		display: none !important;
	}
	select {
		position: relative;
		padding-right: 1.5em;
		width: 100% !important;
	}
}
