@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1676px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1675px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1675px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding: clamp(1.5em, 2vw, 2em) 5%;
}
@media (min-width: 768px) {
  .breadcrumb {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .breadcrumb {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.78em;
}
@media (min-width: 768px) {
  .breadcrumb__list {
    font-size: 0.85em;
  }
}
.breadcrumb__list .item-home {
  font-size: 1.1em;
}
.breadcrumb__list li {
  position: relative;
  color: #4B4D4E;
}
.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5rem;
}
.breadcrumb__list li:not(:last-child):after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb__list a {
  color: #979DA4;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}
.breadcrumb__list a:first-child {
  text-decoration: none !important;
}
.breadcrumb__list a:hover {
  color: #00A0E9;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/
.news-archive__list {
  padding-top: clamp(2rem, 3vw, 3rem);
  row-gap: clamp(2rem, 4vw, 4rem);
  display: flex;
  flex-direction: column;
}

.news-box {
  position: relative;
  background: #ffffff;
  text-align: left;
}
.news-box a {
  display: block;
  position: relative;
  height: 100%;
  color: #222222;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.news-box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.news-box__inner {
  display: grid;
}
@media (min-width: 576px) {
  .news-box__inner {
    column-gap: 5%;
    grid-template-columns: 40% 1fr;
  }
}
@media (min-width: 1176px) {
  .news-box__inner {
    grid-template-columns: 300px 1fr;
  }
}
@media (max-width: 575px) {
  .news-box__inner {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }
}
.news-box__img {
  grid-row: 1;
}
@media (min-width: 576px) {
  .news-box__img {
    grid-column: 1/2;
  }
}
.news-box__img__inner {
  margin: 0;
}
@media (min-width: 576px) {
  .news-box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .news-box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .news-box__img__inner > div,
  .news-box__img__inner figure,
  .news-box__img__inner a,
  .news-box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .news-box__img__inner {
    width: 100%;
    height: 100%;
  }
}
.news-box__txt {
  grid-row: 2;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
@media (min-width: 576px) {
  .news-box__txt {
    padding-top: 1em;
    padding-bottom: 1em;
    grid-column: 2/3;
    grid-row: 1;
  }
}
.news-box__txt__sub {
  display: flex;
  column-gap: 1em;
  margin-bottom: 1em;
}
.news-box__txt__sub .is-date {
  color: #00A0E9;
  font-size: 1.14em;
  font-weight: 500;
  letter-spacing: 0;
  font-family: 'Poppins', sans-serif;
}
.news-box__txt__sub .is-category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.news-box__txt__ttl {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #C9CED2;
  font-weight: 600;
  font-size: calc(1rem + 4 * (100vw - 280px) / 1300);
}
@media (min-width: 1176px) {
  .news-box__txt__ttl {
    font-size: 1.25rem;
  }
}
.news-box__txt__txt {
  font-size: 0.94em;
  line-height: 1.5;
}

.news-content {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 1176px) {
  .news-content {
    font-size: 1.14em;
  }
}
@media (min-width: 1921px) {
  .news-content {
    max-width: 62.5vw;
  }
}

.pdf-btn {
  max-width: 480px;
}
@media (min-width: 1921px) {
  .pdf-btn {
    max-width: 25vw;
  }
}
.pdf-btn a {
  padding: 1.25em 4% 1.25em 4.5em;
  border: 1px solid #979DA4;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #222222;
  background: #ffffff;
  font-size: 1.14em;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-weight: 700;
}
.pdf-btn a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1176px) {
  .pdf-btn a {
    font-size: 1.25em;
  }
}
.pdf-btn a::before {
  position: absolute;
  left: 1em;
  top: 0.7em;
  font-size: 1.8em;
  color: #979DA4;
  transition: all 0.3s ease 0s;
}
.pdf-btn a:hover {
  background: #008bf5;
  border-color: #008bf5;
  color: #ffffff;
}
.pdf-btn a:hover::before {
  color: #ffffff;
}

.news-pdf {
  margin-bottom: clamp(3rem, 9vw, 9rem);
}
.news-pdf__list {
  display: flex;
}
@media (min-width: 1176px) {
  .news-pdf__list {
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1em;
  }
  .news-pdf__list > * {
    width: 49%;
  }
}
@media (max-width: 1175px) {
  .news-pdf__list {
    flex-direction: column;
    row-gap: 0.5em;
  }
}
.news-pdf__list li a {
  padding: 1.25em 4% 1.25em 4.5em;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 1.4;
  color: #222222;
  text-decoration: none !important;
  font-weight: 500;
  border-radius: 999px;
}
.news-pdf__list li a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.news-pdf__list li a::before {
  position: absolute;
  left: 1.25em;
  top: 0.8em;
  font-size: 1.6em;
  color: #C9CED2;
}
.news-pdf__list li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #ffffff;
}

/* news-table */
.news-table {
  margin-top: clamp(3rem, 9vw, 9rem);
}
.news-table__list {
  row-gap: clamp(2em, 4vw, 4em);
  display: flex;
  flex-direction: column;
}

.news-table__box__inner {
  border: 1px solid #C9CED2;
  padding: clamp(1.5em, 2vw, 2rem) 5% clamp(1em, 1.5vw, 2rem);
}
@media (min-width: 768px) {
  .news-table__box__inner {
    padding: clamp(2em, 2.5vw, 3rem) 8% clamp(2em, 3vw, 3.5rem);
  }
}
@media (min-width: 1176px) {
  .news-table__box__inner {
    padding: clamp(2em, 3.5vw, 4rem) 10% clamp(2em, 3vw, 3.5rem);
  }
}
.news-table__box__ttl {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
  font-weight: 700;
  font-size: calc(1.25rem + 8 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .news-table__box__ttl {
    font-size: 1.75rem;
  }
}
.news-table__box__table {
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.5;
}
.news-table__box__table tr {
  border-bottom: 1px solid #C9CED2;
}
.news-table__box__table tr:first-child {
  border-top: 1px solid #C9CED2;
}
.news-table__box__table th {
  text-align: left;
  padding: 1rem 0;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .news-table__box__table th {
    padding: 1.25rem 0;
    width: 25%;
    min-width: 180px;
  }
}
@media (min-width: 1553px) {
  .news-table__box__table th {
    width: 225px;
  }
}
@media (max-width: 1175px) {
  .news-table__box__table th {
    display: block;
    padding-bottom: 0;
  }
}
@media (min-width: 1176px) {
  .news-table__box__table td {
    padding: 1.25rem 1.25rem 1.25rem 0.5rem;
  }
}
@media (max-width: 1175px) {
  .news-table__box__table td {
    padding: 1rem 0;
    display: block;
    padding-top: 0;
    padding-left: 0;
  }
}

.news-single__foot {
  margin-top: clamp(4em, 8vw, 8em);
}

/*--------------------------------------------------------------------/
	about
/--------------------------------------------------------------------*/
.about-intro {
  position: relative;
  padding-bottom: clamp(6rem, 9vw, 9rem);
}
.about-intro__inner {
  position: relative;
  display: flex;
}
@media (min-width: 768px) {
  .about-intro__inner {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .about-intro__inner {
    flex-direction: column;
  }
}
.about-intro__img {
  z-index: 2;
}
@media (min-width: 768px) {
  .about-intro__img {
    position: absolute;
    top: 0;
    right: 54%;
    width: 37%;
  }
}
@media (max-width: 767px) {
  .about-intro__img {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.about-intro__txt {
  display: block;
  position: relative;
  padding-top: clamp(6rem, 25vw, 20rem);
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: clamp(3rem, 6vw, 6rem);
  background: #EEF5FA;
  z-index: 1;
  margin-top: -20vw;
}
@media (min-width: 768px) {
  .about-intro__txt {
    margin-top: 3em;
    width: 60%;
    padding-right: 8%;
    padding-left: 12%;
    padding-top: clamp(3rem, 6vw, 6rem);
    padding-bottom: clamp(3rem, 6vw, 6rem);
  }
}
.about-intro__txt__inner {
  font-size: clamp(12px, 4.29vw, 36px);
  line-height: 1.5;
  font-weight: 700;
}
@media (min-width: 768px) {
  .about-intro__txt__inner {
    font-size: 2vw;
  }
}
@media (min-width: 1676px) {
  .about-intro__txt__inner {
    font-size: 2.25rem;
  }
}
.about-intro__txt__inner strong {
  font-size: 1.4em;
  padding-bottom: 0.2em;
  color: #00A0E9;
  border-bottom: 2px solid #00A0E9;
}

.about-explanation {
  position: relative;
  padding-top: clamp(6rem, 14vw, 14rem);
  padding-bottom: clamp(6rem, 9vw, 9rem);
}
.about-explanation__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
}
@media (min-width: 1176px) {
  .about-explanation__bg {
    min-height: 750px;
  }
}
.about-explanation__inner {
  position: relative;
  z-index: 2;
}
.about-explanation__ttl {
  color: #ffffff;
  text-align: center;
  margin-bottom: clamp(1.5em, 6vw, 3em);
}
.about-explanation__ttl b {
  font-weight: 700;
  font-size: calc(1.14rem + 32 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .about-explanation__ttl b {
    font-size: 3.13rem;
  }
}
.about-explanation__ttl em {
  display: block;
  font-style: normal;
  font-size: calc(0.9rem + 8 * (100vw - 280px) / 1300);
  font-weight: 600;
}
@media (min-width: 1553px) {
  .about-explanation__ttl em {
    font-size: 1.5rem;
  }
}
.about-explanation__txt {
  position: relative;
  max-width: 1200px;
  padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(3em, 6vw, 6rem);
  background: #ffffff;
  border: 1px solid #C9CED2;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .about-explanation__txt {
    padding: clamp(2em, 5vw, 5em) clamp(1.5em, 8%, 8em) clamp(3em, 6vw, 6rem);
  }
}
@media (min-width: 1176px) {
  .about-explanation__txt {
    padding: clamp(2em, 5vw, 5em) clamp(1.5em, 15%, 12em) clamp(3em, 6vw, 6rem);
  }
}
.about-explanation__txt__img {
  width: 60%;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
  margin-left: auto !important;
  margin-right: auto !important;
}
.about-explanation__txt__txt {
  line-height: 2;
}
@media (min-width: 1176px) {
  .about-explanation__txt__txt {
    font-size: 1.14em;
  }
}

.about-greetings {
  position: relative;
  padding-top: 48vw;
  padding-bottom: clamp(3rem, 6vw, 6rem);
  overflow: hidden;
}
@media (min-width: 768px) {
  .about-greetings {
    padding-top: clamp(6rem, 10vw, 12rem);
  }
}
.about-greetings__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
}
@media (min-width: 768px) {
  .about-greetings__img {
    width: 61.45%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .about-greetings__img figure {
    height: 100%;
  }
}

.about-greetings .l-cont {
  position: relative;
  z-index: 2;
}
.about-greetings__inner {
  position: relative;
  padding-top: clamp(2em, 3vw, 3em);
  padding-right: clamp(1.5em, 5%, 3em);
  padding-bottom: clamp(2em, 3vw, 3em);
  padding-left: clamp(1.5em, 5%, 3em);
  background: #ffffff;
}
@media (min-width: 768px) {
  .about-greetings__inner {
    width: 90%;
    padding-top: clamp(3em, 5vw, 5em);
    padding-right: clamp(2em, 8%, 7em);
    padding-bottom: clamp(3em, 5vw, 5em);
    padding-left: 0;
  }
}
@media (min-width: 1176px) {
  .about-greetings__inner {
    width: 80%;
  }
}
.about-greetings__flex {
  display: flex;
}
@media (min-width: 768px) {
  .about-greetings__flex {
    column-gap: 8%;
  }
}
@media (max-width: 767px) {
  .about-greetings__flex {
    flex-direction: column;
    row-gap: 2em;
  }
}
@media (min-width: 768px) {
  .about-greetings__flex .about-greetings__txt {
    flex-shrink: 1;
    width: 68%;
  }
}
.about-greetings__ttl {
  margin-bottom: clamp(2em, 3vw, 3em);
}
.about-greetings__ttl em {
  position: relative;
  display: block;
  font-style: normal;
  text-transform: capitalize;
  color: #00A0E9;
  line-height: 1.2;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1300);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
@media (min-width: 1553px) {
  .about-greetings__ttl em {
    font-size: 5.38rem;
  }
}
.about-greetings__ttl b {
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .about-greetings__ttl b {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .about-greetings__txt {
    width: 95%;
  }
}
@media (min-width: 1553px) {
  .about-greetings__txt {
    font-size: 1.14em;
  }
}
@media (min-width: 1176px) {
  .about-greetings__txt__txt {
    line-height: 2;
  }
}
.about-greetings__txt__txt p:not(:last-child) {
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .about-greetings__pic {
    min-width: 180px;
    width: 24%;
  }
}
@media (max-width: 767px) {
  .about-greetings__pic {
    max-width: 220px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.about-greetings__pic figcaption {
  margin-top: 1rem;
  font-size: 1em;
  text-align: center;
  font-weight: 700;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 1553px) {
  .about-greetings__pic figcaption {
    font-size: 1em;
  }
}

.about-greetings__bg {
  position: absolute;
  content: "";
  display: block;
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 576px) {
  .about-greetings__bg {
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
  }
}
@media (min-width: 1176px) {
  .about-greetings__bg {
    width: 30%;
  }
}
@media (max-width: 575px) {
  .about-greetings__bg {
    width: 100%;
    height: 40vw;
    left: 0;
    bottom: 0;
  }
}
.about-greetings__bg__inner {
  position: relative;
  height: 100%;
}
.about-greetings__bg__inner::after {
  content: "";
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media (min-width: 576px) and (max-width: 1175px) {
  .about-greetings__bg__inner::after {
    background: rgba(255, 255, 255, 0.7);
  }
}
.about-greetings__bg__inner > * {
  height: 100%;
}

.about-philosophy {
  padding-top: clamp(5rem, 10vw, 12rem);
  padding-bottom: clamp(6rem, 12vw, 12rem);
  margin-bottom: clamp(6rem, 12vw, 12rem);
  position: relative;
  overflow: hidden;
}
.about-philosophy .l-cont {
  position: relative;
  z-index: 2;
}
.about-philosophy__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .about-philosophy__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .about-philosophy__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

@media (min-width: 1176px) {
  .about-philosophy__ttl {
    flex-basis: 38%;
  }
}
@media (min-width: 1553px) {
  .about-philosophy__ttl {
    flex-basis: 31.25%;
  }
}
.about-philosophy__ttl__inner {
  color: #ffffff;
}
.about-philosophy__ttl__inner em {
  font-style: normal;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  display: block;
  margin-bottom: 0.2em;
  font-family: 'Poppins', sans-serif;
  font-size: calc(2.25rem + 24 * (100vw - 280px) / 1300);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1553px) {
  .about-philosophy__ttl__inner em {
    font-size: 3.75rem;
  }
}
.about-philosophy__ttl__inner b {
  display: block;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .about-philosophy__ttl__inner b {
    font-size: 1.75rem;
  }
}

.about-philosophy__txt {
  line-height: 2;
}
@media (min-width: 1176px) {
  .about-philosophy__txt {
    flex-basis: 60%;
  }
}
@media (min-width: 1553px) {
  .about-philosophy__txt {
    flex-basis: 62.32%;
  }
}
.about-philosophy__txt__inner {
  background: #ffffff;
  padding: clamp(1.5rem, 4vw, 4rem) 5%;
}
@media (min-width: 768px) {
  .about-philosophy__txt__inner {
    padding: clamp(2rem, 4vw, 4rem) 8%;
  }
}
@media (min-width: 1553px) {
  .about-philosophy__txt__inner {
    padding: clamp(2rem, 5vw, 5rem) 10%;
  }
}
.about-philosophy__txt__inner .txt {
  font-size: calc(0.94rem + 4 * (100vw - 280px) / 1300);
}
@media (min-width: 1176px) {
  .about-philosophy__txt__inner .txt {
    font-size: calc(1rem + 2 * (100vw - 1080px) / 500);
  }
}
@media (min-width: 1553px) {
  .about-philosophy__txt__inner .txt {
    font-size: 1.13rem;
  }
}
.about-philosophy__txt__inner .txt p:not(:last-child) {
  margin-bottom: 1.5em;
}
.about-philosophy__txt__inner .txt strong {
  font-size: 1.14em;
}

.about-philosophy__bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #00A0E9;
}
.about-philosophy__bg__inner {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.about-philosophy__bg__inner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.about-table {
  padding-top: clamp(3rem, 9vw, 9rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
  display: flex;
  flex-direction: column;
  row-gap: clamp(6rem, 9vw, 9rem);
}
.about-table__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .about-table__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .about-table__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .about-table__ttl {
    width: 30.9%;
  }
}
@media (min-width: 1176px) {
  .about-table__table {
    width: 65.55%;
  }
}

.about-articles {
  width: 100%;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.about-articles__inner {
  width: 100%;
  display: flex;
}
@media (min-width: 1176px) {
  .about-articles__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .about-articles__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .about-articles__ttl {
    width: 30.9%;
  }
}
@media (min-width: 1176px) {
  .about-articles__txt {
    width: 65.55%;
  }
}
.about-articles__txt .txt {
  margin-bottom: 1.5em;
}
.about-articles__btn a {
  padding: 1.25em 4% 1.25em 4.5em;
  border: 1px solid #054497;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  background: #054497;
  font-size: 1.14em;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-weight: 700;
  border-radius: 999px;
}
.about-articles__btn a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1176px) {
  .about-articles__btn a {
    font-size: 1.25em;
  }
}
.about-articles__btn a::before {
  position: absolute;
  left: 1.2em;
  top: 0.7em;
  font-size: 1.8em;
  color: #ffffff;
}
.about-articles__btn a:hover {
  background: #008bf5;
  border-color: #008bf5;
  color: #ffffff;
}

.about-reports {
  width: 100%;
}
.about-reports__inner {
  width: 100%;
  display: flex;
}
@media (min-width: 1176px) {
  .about-reports__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .about-reports__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .about-reports__ttl {
    width: 30.9%;
  }
}
@media (min-width: 1176px) {
  .about-reports__txt {
    width: 65.55%;
  }
}
.about-reports__txt .txt {
  margin-bottom: 1.5em;
}
.about-reports__btn a {
  padding: 1.25em 4% 1.25em 4.5em;
  border: 1px solid #054497;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  background: #054497;
  font-size: 1.14em;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-weight: 700;
  border-radius: 999px;
}
.about-reports__btn a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1176px) {
  .about-reports__btn a {
    font-size: 1.25em;
  }
}
.about-reports__btn a::before {
  position: absolute;
  left: 1.2em;
  top: 0.7em;
  font-size: 1.8em;
  color: #ffffff;
}
.about-reports__btn a:hover {
  background: #008bf5;
  border-color: #008bf5;
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	poverty
/--------------------------------------------------------------------*/
.poverty-intro {
  position: relative;
  padding-top: 48vw;
  overflow: hidden;
}
@media (min-width: 768px) {
  .poverty-intro {
    padding-top: clamp(6rem, 14vw, 14rem);
  }
}
.poverty-intro__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
}
@media (min-width: 768px) {
  .poverty-intro__img {
    width: 85%;
  }
}
.poverty-intro__img picture {
  position: relative;
  aspect-ratio: 38/27;
}
@media (min-width: 768px) {
  .poverty-intro__img picture {
    aspect-ratio: 116/75;
  }
}

.poverty-intro__txt {
  position: relative;
  z-index: 2;
  padding-left: 5%;
  padding-right: 5%;
}
.poverty-intro__txt__inner {
  padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 5%, 4em);
  background: #ffffff;
}
@media (min-width: 768px) {
  .poverty-intro__txt__inner {
    width: 65%;
    padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 6%, 6em);
  }
}
@media (min-width: 1553px) {
  .poverty-intro__txt__inner {
    padding: clamp(2em, 6vw, 6rem) clamp(1.5em, 7%, 8em) clamp(2em, 6vw, 6rem) clamp(1.5em, 6%, 8em);
    width: 60%;
    min-height: 600px;
  }
}
.poverty-intro__txt__ttl {
  padding-bottom: 0.75em;
  color: #00A0E9;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #00A0E9;
  font-size: clamp(15px, 5.36vw, 44px);
  font-weight: 700;
}
@media (min-width: 768px) {
  .poverty-intro__txt__ttl {
    font-size: clamp(20px, 2.6vw, 44px);
  }
}
@media (min-width: 1728px) {
  .poverty-intro__txt__ttl {
    font-size: 2.75rem;
  }
}
.poverty-intro__txt__ttl strong {
  font-size: 1.5em;
  font-weight: 700;
}
@media (min-width: 768px) {
  .poverty-intro__txt__txt {
    font-size: clamp(13px, 1.69vw, 22px);
  }
}
@media (min-width: 1553px) {
  .poverty-intro__txt__txt {
    font-size: 1.38rem;
  }
}
.poverty-intro__txt__txt strong {
  color: #222222;
  font-size: clamp(11px, 3.93vw, 20px);
  line-height: 1.4;
  font-weight: 700;
}
@media (min-width: 768px) {
  .poverty-intro__txt__txt strong {
    font-size: 2vw;
  }
}
@media (min-width: 1553px) {
  .poverty-intro__txt__txt strong {
    font-size: 1.3em;
  }
}
.poverty-intro__txt__txt strong.is-big {
  font-size: clamp(15px, 5.36vw, 30px);
}
@media (min-width: 768px) {
  .poverty-intro__txt__txt strong.is-big {
    font-size: 3vw;
  }
}
@media (min-width: 1553px) {
  .poverty-intro__txt__txt strong.is-big {
    font-size: 1.75em;
  }
}

.poverty-expenditure {
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(6rem, 9vw, 9rem);
}
.poverty-expenditure__inner {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.poverty-expenditure__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5em 4%;
}
@media (min-width: 768px) {
  .poverty-expenditure__list {
    grid-template-columns: repeat(4, 1fr);
    gap: 2em 2%;
  }
}
.poverty-expenditure__item {
  overflow: hidden;
  border-radius: 50%;
}

.poverty-text {
  position: relative;
  padding-top: clamp(3rem, 9vw, 9rem);
  padding-bottom: clamp(9rem, 38vw, 26rem);
  background: #F6F6F6;
  overflow: hidden;
}
@media (min-width: 768px) {
  .poverty-text {
    padding-bottom: clamp(6rem, 9vw, 9rem);
  }
}
.poverty-text__txt {
  position: relative;
  z-index: 2;
  font-size: calc(1.14rem + 20 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 768px) {
  .poverty-text__txt {
    padding-left: 50%;
    font-size: 2.75vw;
  }
}
@media (min-width: 1676px) {
  .poverty-text__txt {
    font-size: 2.75rem;
  }
}
.poverty-text__add {
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  .poverty-text__add {
    left: 7%;
    width: 34.375%;
  }
}
@media (min-width: 1676px) {
  .poverty-text__add {
    width: 660px;
  }
}
@media (max-width: 767px) {
  .poverty-text__add {
    width: 55%;
    left: 55%;
  }
}

/*--------------------------------------------------------------------/
	scholarship
/--------------------------------------------------------------------*/
.scholarship-catch {
  position: relative;
  padding-top: clamp(3rem, 9vw, 9rem);
  padding-bottom: clamp(3rem, 9vw, 9rem);
  overflow: hidden;
  background: url(../img/lv2/scholarship_intro_bg.jpg) center center/cover no-repeat;
}
.scholarship-catch::before {
  position: absolute;
  top: 0;
  right: 64%;
  content: "";
  width: 100%;
  height: 100%;
  background: #054497;
  transform-origin: top right;
  transform: skew(-30deg);
}
@media (min-width: 768px) {
  .scholarship-catch::before {
    right: 70%;
  }
}
.scholarship-catch__inner {
  position: relative;
  z-index: 2;
  color: #ffffff;
  padding-left: 5%;
}
@media (min-width: 768px) {
  .scholarship-catch__inner {
    padding-left: 10%;
  }
}
.scholarship-catch__inner p {
  line-height: 1.4;
  font-size: clamp(16px, 5.71vw, 80px);
  font-weight: 900;
}
@media (min-width: 1676px) {
  .scholarship-catch__inner p {
    font-size: 5rem;
  }
}

.scholarship-philosophy {
  padding-top: clamp(4rem, 9vw, 9rem);
  padding-bottom: clamp(4rem, 9vw, 9rem);
}
.scholarship-philosophy__inner {
  display: grid;
  max-width: 1200px;
  grid-template-columns: 50% 50%;
  place-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 576px) {
  .scholarship-philosophy__inner {
    grid-template-columns: 45% 45%;
  }
}
@media (min-width: 1921px) {
  .scholarship-philosophy__inner {
    max-width: 62.5vw;
  }
}

.scholarship-philosophy__box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .scholarship-philosophy__box {
    padding: 2em;
  }
}
@media (max-width: 767px) {
  .scholarship-philosophy__box {
    padding-bottom: 1.5em;
    padding-bottom: 1.5em;
  }
}
.scholarship-philosophy__box:nth-of-type(odd) {
  align-items: flex-start;
}
.scholarship-philosophy__box:nth-of-type(odd)::after {
  position: absolute;
  top: 0;
  left: 96%;
  content: "";
  width: 1px;
  height: 100%;
  background: #00A0E9;
  transform: rotate(15deg);
}
.scholarship-philosophy__box:nth-of-type(even) {
  align-items: flex-end;
}
.scholarship-philosophy__box__inner {
  text-align: center;
}
.scholarship-philosophy__box__ttl {
  position: relative;
  padding-bottom: 3vw;
  margin-bottom: clamp(1.5em, 2vw, 2em);
}
@media (min-width: 1553px) {
  .scholarship-philosophy__box__ttl {
    padding-bottom: 1.5em;
  }
}
.scholarship-philosophy__box__ttl::before {
  position: absolute;
  left: calc(50% - 1.5em);
  bottom: 0;
  content: "";
  width: 3em;
  height: 3px;
  background: #00A0E9;
  border-radius: 999px;
}
.scholarship-philosophy__box__ttl em {
  position: relative;
  display: block;
  font-style: normal;
  text-transform: capitalize;
  color: #00A0E9;
  line-height: 1;
  font-size: calc(1.14rem + 70 * (100vw - 280px) / 1300);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
@media (min-width: 1553px) {
  .scholarship-philosophy__box__ttl em {
    font-size: 5.63rem;
  }
}
.scholarship-philosophy__box__ttl b {
  font-size: calc(0.8rem + 16 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .scholarship-philosophy__box__ttl b {
    font-size: 1.75rem;
  }
}
.scholarship-philosophy__box__txt {
  line-height: 1.5;
  font-weight: 700;
  font-size: calc(0.8rem + 20 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .scholarship-philosophy__box__txt {
    font-size: 2.25rem;
  }
}

.scholarship-system {
  position: relative;
  padding-top: clamp(6rem, 9vw, 9rem);
  padding-bottom: clamp(6rem, 9vw, 6rem);
  background: #054497;
}
.scholarship-system__inner {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.scholarship-system .heading-ttl {
  color: #ffffff;
}
.scholarship-system__list {
  display: grid;
}
@media (min-width: 768px) {
  .scholarship-system__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2em 2%;
  }
}
@media (max-width: 767px) {
  .scholarship-system__list {
    grid-template-columns: 1fr;
    row-gap: 1.5em;
    max-width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.scholarship-system__box {
  padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 4em);
  background: #ffffff;
  container: systembox/inline-size;
}
.scholarship-system__box__ttl {
  padding: 0.4em 1.25em;
  width: fit-content;
  text-align: center;
  color: #ffffff;
  background: #00A0E9;
  font-size: 1.25em;
  margin-bottom: 1em;
  line-height: 1.2;
  margin-left: auto !important;
  margin-right: auto !important;
}
.scholarship-system__box__txt {
  font-size: 0.94em;
}
.scholarship-system__box__txt .is-catch {
  text-align: center;
  font-size: 1.2em;
  line-height: 1.4;
  font-weight: 700;
}
.scholarship-system__box__txt .is-catch strong {
  font-size: 1.3em;
}
@container systembox (width > 280px) {
  .scholarship-system__box__txt .is-catch {
    font-size: 1.4em;
  }
}
.scholarship-system__box__txt p {
  margin-top: 1.5em;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  line-height: 1.5;
}
.scholarship-system__box__txt p strong {
  font-size: 1.3em;
}

.scholarship-text {
  position: relative;
  padding-top: clamp(6rem, 12vw, 12rem);
  padding-bottom: clamp(12rem, 42vw, 30rem);
  background: #EEF5FA;
}
@media (min-width: 768px) {
  .scholarship-text {
    padding-top: clamp(4rem, 9vw, 9rem);
    padding-bottom: clamp(6rem, 12vw, 12rem);
  }
}
.scholarship-text__txt {
  max-width: 1200px;
  font-size: 1.07em;
  font-weight: 700;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .scholarship-text__txt {
    font-size: 1.25em;
    padding-left: 13%;
  }
}
@media (min-width: 1176px) {
  .scholarship-text__txt {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .scholarship-text__txt .txt {
    width: 75%;
  }
}
.scholarship-text__add01 {
  position: absolute;
  top: -15vw;
  left: 4%;
  width: clamp(70px, 16%, 110px);
}
@media (min-width: 768px) {
  .scholarship-text__add01 {
    width: 11%;
    top: -3vw;
    left: 3%;
  }
}
@media (min-width: 1176px) {
  .scholarship-text__add01 {
    top: -3em;
    left: auto;
    right: calc(50% + 680px);
  }
}
.scholarship-text__add02 {
  position: absolute;
  bottom: 0;
  right: 4%;
  width: 36%;
}
@media (min-width: 768px) {
  .scholarship-text__add02 {
    width: 21.25%;
  }
}
@media (min-width: 1176px) {
  .scholarship-text__add02 {
    right: 8%;
  }
}

/*--------------------------------------------------------------------/
	recruit
/--------------------------------------------------------------------*/
.recruit-upper {
  position: relative;
  width: 100%;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-upper::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(160px, 38.54vw, 740px);
  height: clamp(160px, 38.54vw, 740px);
  background: #EEF5FA;
  z-index: 1;
  display: block;
}
.recruit-upper .l-cont_full {
  position: relative;
  z-index: 2;
}
.recruit-upper__inner {
  position: relative;
}

.recruit-upper__ttl {
  position: relative;
  padding-left: 10%;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-upper__ttl__inner {
  font-weight: 700;
}
.recruit-upper__ttl__inner small {
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1300);
  letter-spacing: 0.02em;
  padding-left: 0.5em;
}
@media (min-width: 1553px) {
  .recruit-upper__ttl__inner small {
    font-size: 2.25rem;
  }
}
.recruit-upper__ttl__inner strong {
  display: block;
  font-weight: 700;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1300);
  line-height: 1;
  position: relative;
}
@media (min-width: 1553px) {
  .recruit-upper__ttl__inner strong {
    font-size: 5rem;
  }
}
.recruit-upper__ttl__inner strong b {
  color: #00A0E9;
  font-size: 1.5em;
}
.recruit-upper__ttl__inner strong::after {
  position: absolute;
  top: calc(50% + 2px);
  right: calc(100% + 0.2em);
  content: "";
  width: 100%;
  height: 2px;
  background: #00A0E9;
}

.recruit-upper__txt {
  padding-left: 10%;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-upper__txt__inner {
  width: 90%;
}
@media (min-width: 768px) {
  .recruit-upper__txt__inner {
    width: 50%;
  }
}
.recruit-upper__list {
  display: flex;
  counter-reset: merit-num;
}
@media (min-width: 768px) {
  .recruit-upper__list {
    flex-wrap: wrap;
    column-gap: 4.64%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .recruit-upper__list > *:nth-of-type(even) {
    margin-top: 9vw;
  }
}
@media (min-width: 768px) and (min-width: 1553px) {
  .recruit-upper__list > *:nth-of-type(even) {
    margin-top: 134px;
  }
}
@media (max-width: 767px) {
  .recruit-upper__list {
    flex-direction: column;
    row-gap: clamp(2rem, 4vw, 4rem);
  }
}

@media (min-width: 768px) {
  .recruit-upper__box {
    width: 47.68%;
  }
}
@media (max-width: 767px) {
  .recruit-upper__box {
    width: 100%;
  }
}
.recruit-upper__box__inner {
  position: relative;
}
.recruit-upper__box__img {
  position: relative;
  z-index: 1;
  width: 100%;
}
@media (min-width: 768px) {
  .recruit-upper__box__img {
    width: 94%;
  }
}
@media (min-width: 1176px) {
  .recruit-upper__box__img {
    width: 87.37%;
  }
}
.recruit-upper__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.recruit-upper__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}
.recruit-upper__box__img__inner > div,
.recruit-upper__box__img__inner figure,
.recruit-upper__box__img__inner a,
.recruit-upper__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.recruit-upper__box__txt {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .recruit-upper__box__txt {
    margin-top: -3rem;
  }
}
@media (min-width: 1176px) {
  .recruit-upper__box__txt {
    margin-top: -4rem;
  }
}
@media (max-width: 767px) {
  .recruit-upper__box__txt {
    margin-top: -2rem;
  }
}
.recruit-upper__box__txt__inner {
  width: 100%;
  background: #ffffff;
  padding: 0 5% clamp(1.5rem, 3vw, 3rem);
  position: relative;
}
@media (min-width: 768px) {
  .recruit-upper__box__txt__inner {
    width: 92%;
  }
}
@media (min-width: 1553px) {
  .recruit-upper__box__txt__inner {
    width: 82.52%;
    padding: 0 10% clamp(1.5rem, 3vw, 3rem);
  }
}
.recruit-upper__box__txt__inner::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 2em;
  background: #00A0E9;
}
.recruit-upper__box__txt__inner::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2em;
  height: 1px;
  background: #00A0E9;
}
.recruit-upper__box__txt__ttl {
  font-weight: 700;
  position: relative;
  margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
}
.recruit-upper__box__txt__ttl span {
  padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 4rem;
  display: block;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1300);
}
@media (min-width: 576px) {
  .recruit-upper__box__txt__ttl span {
    padding: clamp(1.5rem, 2vw, 2rem) 5% 0.5em 12%;
  }
}
@media (min-width: 768px) {
  .recruit-upper__box__txt__ttl span {
    font-size: calc(1.25rem + 12 * (100vw - 768px) / 812);
    padding: 2rem 3% 0.5em 14%;
  }
}
@media (min-width: 1553px) {
  .recruit-upper__box__txt__ttl span {
    font-size: 2rem;
    padding: 2rem 5% 0.5em 4.5rem;
  }
}
.recruit-upper__box__txt__ttl span::after {
  content: "";
  position: absolute;
  left: 1.25em;
  top: -1em;
  width: clamp(4rem, 7vw, 7rem);
  width: 1px;
  height: 3.5em;
  transform-origin: bottom left;
  transform: rotate(40deg);
  background: #00A0E9;
}
@media (min-width: 768px) {
  .recruit-upper__box__txt__ttl span::after {
    top: -1.5em;
    height: calc(3.5rem + 2em);
    left: 0.2em;
  }
}
@media (min-width: 1553px) {
  .recruit-upper__box__txt__ttl span::after {
    top: -2em;
    left: 0.2em;
    height: calc(4rem + 3em);
  }
}
.recruit-upper__box__txt__ttl::before {
  position: absolute;
  left: 0;
  bottom: calc(100% - 0.4em);
  line-height: 1;
  counter-increment: merit-num;
  content: counter(merit-num, decimal-leading-zero);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #00A0E9;
  font-size: calc(3rem + 32 * (100vw - 280px) / 1300);
}
@media (min-width: 768px) {
  .recruit-upper__box__txt__ttl::before {
    font-size: calc(2.5rem + 30 * (100vw - 768px) / 812);
    left: -0.2em;
  }
}
@media (min-width: 1553px) {
  .recruit-upper__box__txt__ttl::before {
    font-size: 5rem;
    left: -0.3em;
  }
}
/*.recruit-remarks */
.recruit-remarks {
  position: relative;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-remarks__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 3vw, 3rem);
}

.recruit-remarks__box {
  border: 1px solid #C9CED2;
  padding: clamp(1.5em, 2vw, 2rem) 5%;
}
@media (min-width: 1176px) {
  .recruit-remarks__box {
    padding: clamp(2em, 3vw, 3rem) 7% clamp(2em, 3vw, 3rem);
  }
}

/*.recruit-introduction */
.recruit-introduction {
  margin-bottom: clamp(6rem, 9vw, 9rem);
  position: relative;
}
.recruit-introduction__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}

.recruit-introduction__box {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.recruit-introduction__box__flex {
  display: flex;
  width: 100%;
}
@media (min-width: 768px) {
  .recruit-introduction__box__flex {
    column-gap: 5%;
    align-items: center;
  }
}
@media (min-width: 1553px) {
  .recruit-introduction__box__flex {
    column-gap: 5.18%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__flex {
    flex-direction: column;
    row-gap: 1.2em;
  }
}
@media (min-width: 768px) {
  .recruit-introduction__box__flex.is-right {
    flex-direction: row-reverse;
  }
  .recruit-introduction__box__flex.is-right .recruit-introduction__box__img::before {
    left: 10%;
  }
  .recruit-introduction__box__flex.is-right .recruit-introduction__box__txt {
    padding-left: 5%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .recruit-introduction__box__flex.is-left .recruit-introduction__box__img::before {
    right: 10%;
  }
  .recruit-introduction__box__flex.is-left .recruit-introduction__box__txt {
    padding-right: 5%;
  }
}
@media (min-width: 768px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt {
    width: 60%;
  }
}
@media (min-width: 1553px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt {
    width: 46.91%;
  }
}
@media (max-width: 1552px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt {
    padding-right: 5%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 1176px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt__inner {
    max-width: 680px;
  }
}
@media (min-width: 1921px) {
  .recruit-introduction__box__flex .recruit-introduction__box__txt__inner {
    max-width: 35.41vw;
  }
}

.recruit-introduction__box__img {
  position: relative;
}
@media (min-width: 768px) {
  .recruit-introduction__box__img {
    padding-bottom: clamp(2rem, 5vw, 5rem);
    width: 45%;
  }
}
@media (min-width: 1553px) {
  .recruit-introduction__box__img {
    width: 47.91%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__img {
    width: 90%;
    padding-bottom: 1.2rem;
  }
}
.recruit-introduction__box__img::before {
  background: #DFEFFE;
  z-index: -1;
  position: absolute;
  bottom: 0;
  content: "";
}
@media (min-width: 768px) {
  .recruit-introduction__box__img::before {
    height: 100%;
    width: 90%;
  }
}
@media (max-width: 767px) {
  .recruit-introduction__box__img::before {
    left: 10%;
    bottom: 0;
    content: "";
    width: 100vw;
    height: 90%;
  }
}
.recruit-introduction__box__txt__ttl {
  margin-bottom: clamp(1rem, 2vw, 2rem);
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1300);
}
@media (min-width: 768px) {
  .recruit-introduction__box__txt__ttl {
    font-size: calc(1.25rem + 8 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .recruit-introduction__box__txt__ttl {
    font-size: 1.88rem;
  }
}

/* recruit-gallery */
.recruit-gallery {
  overflow: hidden;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(3rem, 6vw, 6rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
}
.recruit-gallery__ttl {
  font-weight: 400;
  line-height: 1.4;
  position: relative;
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
.recruit-gallery__ttl__inner {
  position: relative;
  padding-right: 1em;
  height: 100%;
}
.recruit-gallery__ttl__inner::after {
  content: "";
  position: absolute;
  top: 30%;
  left: 100%;
  width: 100vw;
  height: 2px;
  background: #00A0E9;
}
.recruit-gallery__ttl em {
  font-style: normal;
  padding-right: 0.2em;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.03em;
  font-size: calc(2.5rem + 46 * (100vw - 280px) / 1300);
  font-weight: 400;
}
@media (min-width: 1553px) {
  .recruit-gallery__ttl em {
    font-size: 6rem;
  }
}
.recruit-gallery__ttl b {
  color: #00A0E9;
  display: inline-block;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(0.85rem + 12 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .recruit-gallery__ttl b {
    font-size: 1.75rem;
  }
}

.recruit-gallery__box__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.recruit-gallery__box__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.recruit-gallery__box__inner > div,
.recruit-gallery__box__inner figure,
.recruit-gallery__box__inner a,
.recruit-gallery__box__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.recruit-gallery__box figcaption {
  margin-top: 0.5em;
}
@media (max-width: 767px) {
  .is-column .recruit-gallery__box figcaption {
    font-size: 0.71em;
  }
}

.recruit-gallery {
  position: relative;
}
.recruit-gallery .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: clamp(4rem, 8vw, 8rem);
}
.recruit-gallery .swiper-control {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  column-gap: 1.5em;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}
.recruit-gallery .swiper-next_prev {
  display: flex;
  height: 48px;
  flex-shrink: 0;
  width: 102px;
  column-gap: 6px;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-next_prev {
    height: 64px;
    column-gap: 8px;
    width: 136px;
  }
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-next_prev {
    height: 90px;
    width: 188px;
  }
}
.recruit-gallery .swiper-button-prev,
.recruit-gallery .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-button-prev,
  .recruit-gallery .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-button-prev,
  .recruit-gallery .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.recruit-gallery .swiper-button-prev::before,
.recruit-gallery .swiper-button-next::before {
  content: "";
  position: absolute;
  background: transparent;
  border: 1px solid #1F56A9;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.recruit-gallery .swiper-button-prev::after,
.recruit-gallery .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #1F56A9;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1176px) {
  .recruit-gallery .swiper-button-prev::after,
  .recruit-gallery .swiper-button-next::after {
    font-size: 3rem;
  }
}
.recruit-gallery .swiper-button-prev:hover::before,
.recruit-gallery .swiper-button-next:hover::before {
  background: #00A0E9;
  border-color: #00A0E9;
}
.recruit-gallery .swiper-button-prev:hover::after,
.recruit-gallery .swiper-button-next:hover::after {
  color: #ffffff;
}
.recruit-gallery .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.recruit-gallery .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.recruit-gallery .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.recruit-gallery .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.recruit-gallery .swiper-scrollbar {
  width: 100%;
  flex-shrink: 1;
  height: 6px;
  z-index: 1;
  background: #F6F6F6;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-scrollbar {
    height: 8px;
  }
}
.recruit-gallery .swiper-scrollbar-drag {
  background: #C9CED2;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .recruit-gallery .swiper-scrollbar-drag {
    height: 8px;
  }
}
.recruit-gallery .swiper-scrollbar-drag:hover {
  background-color: #00A0E9;
}

/* recruit-table */
.recruit-table {
  margin-bottom: clamp(6rem, 12vw, 12rem);
  display: flex;
  flex-direction: column;
  row-gap: clamp(6rem, 9vw, 9rem);
}
.recruit-table__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .recruit-table__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .recruit-table__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.recruit-table__ttl {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1176px) {
  .recruit-table__ttl {
    width: 30.9%;
  }
}
@media (min-width: 1176px) {
  .recruit-table__table {
    width: 65.55%;
  }
}

/* recruit-form */
.recruit-form__ttl {
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.recruit-form__ttl em {
  font-style: normal;
  display: block;
  margin-bottom: 0.2em;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  font-size: calc(2rem + 28 * (100vw - 280px) / 1300);
  font-weight: 400;
}
@media (min-width: 1553px) {
  .recruit-form__ttl em {
    font-size: 3.75rem;
  }
}
.recruit-form__ttl b {
  color: #00A0E9;
  display: block;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .recruit-form__ttl b {
    font-size: 1.75rem;
  }
}

/*--------------------------------------------------------------------/
	activity
/--------------------------------------------------------------------*/
.activity-intro {
  position: relative;
  padding-top: clamp(2rem, 3vw, 3rem);
  padding-bottom: clamp(6em, 9vw, 9rem);
}
.activity-intro::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 50%;
  background: #DFEFFE;
}
.activity-intro__inner {
  position: relative;
  z-index: 2;
  margin-left: auto !important;
  margin-right: auto !important;
}

.activity-intro__txt {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1553px) {
  .activity-intro__txt {
    font-size: 1.14em;
  }
}
.activity-intro__list {
  display: grid;
}
@media (min-width: 768px) {
  .activity-intro__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2em 2%;
  }
}
@media (max-width: 767px) {
  .activity-intro__list {
    grid-template-columns: 1fr;
    row-gap: 1.5em;
    max-width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.activity-transition {
  padding-top: clamp(4rem, 6vw, 6rem);
  padding-bottom: clamp(6em, 9vw, 9rem);
  background: #EEF5FA;
}
.activity-transition__inner {
  max-width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.activity-transition__list {
  row-gap: clamp(2rem, 5vw, 5rem);
  display: flex;
  flex-direction: column;
}
.activity-transition__img figcaption {
  margin-top: 1em;
}

.activity-support {
  padding-top: clamp(4rem, 6vw, 6rem);
}
.activity-support__inner {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.activity-support__list {
  display: grid;
}
@media (min-width: 576px) {
  .activity-support__list {
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4%;
  }
}
@media (max-width: 575px) {
  .activity-support__list {
    grid-template-columns: 1fr;
    row-gap: clamp(2rem, 5vw, 5rem);
  }
}
.activity-support__img figcaption {
  margin-top: 1em;
}

/*--------------------------------------------------------------------/
	voice
/--------------------------------------------------------------------*/
.voice-intro {
  position: relative;
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
@media (min-width: 768px) {
  .voice-intro__inner {
    padding-top: clamp(3em, 10vw, 6em);
    min-height: 34.21vw;
  }
}
@media (max-width: 767px) {
  .voice-intro__inner {
    display: flex;
    flex-direction: column-reverse;
  }
}
.voice-intro__txt {
  position: relative;
  background: #F6F6F6;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: clamp(1em, 2vw, 2em);
}
@media (min-width: 768px) {
  .voice-intro__txt {
    margin-left: 5%;
    width: 56%;
    padding: clamp(3em, 4vw, 4em) clamp(2em, 5%, 4em) clamp(3em, 4vw, 4em) clamp(2em, 7%, 6em);
  }
}
@media (min-width: 1553px) {
  .voice-intro__txt {
    width: 50%;
    min-height: 420px;
    padding: clamp(3em, 5vw, 5em) clamp(2em, 4%, 5em) clamp(3em, 5vw, 5em) clamp(3em, 12%, 7em);
  }
}
@media (min-width: 1676px) {
  .voice-intro__txt {
    width: 46.875%;
  }
}
@media (max-width: 767px) {
  .voice-intro__txt {
    width: 90%;
    padding: clamp(1.5em, 3.5vw, 3.5em) clamp(1.5em, 5%, 2em) clamp(2em, 4vw, 4em);
    margin-top: -2em;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.voice-intro__txt__ttl {
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 768px) {
  .voice-intro__txt__ttl {
    font-size: calc(1.25rem + 14 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .voice-intro__txt__ttl {
    font-size: 2.25rem;
  }
}
.voice-intro__txt__name {
  color: #00A0E9;
  line-height: 1.5;
  font-weight: 700;
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1300);
}
@media (min-width: 768px) {
  .voice-intro__txt__name {
    font-size: calc(1rem + 4 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .voice-intro__txt__name {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .voice-intro__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 51.35vw;
    aspect-ratio: 3/2;
    z-index: 1;
  }
}

.voice-list {
  counter-reset: voice-num;
  row-gap: clamp(3rem, 6vw, 6rem);
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1921px) {
  .voice-list {
    max-width: 62.5vw;
  }
}

.voice-item__ttl {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  align-items: center;
  padding-bottom: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid #C9CED2;
  font-weight: 700;
  font-size: calc(1rem + 6 * (100vw - 280px) / 1300);
}
@media (min-width: 1176px) {
  .voice-item__ttl {
    font-size: 1.37em;
  }
}
.voice-item__ttl::before {
  counter-increment: voice-num;
  content: counter(voice-num, decimal-leading-zero);
  justify-self: start;
  color: #00A0E9;
  font-size: 2.5em;
  line-height: 1;
  letter-spacing: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
@media (min-width: 1176px) {
  .voice-item__txt {
    font-size: 1.14em;
  }
}

.voice-single__foot {
  margin-top: clamp(4em, 8vw, 8em);
}

/*--------------------------------------------------------------------/
	donation
/--------------------------------------------------------------------*/
.donation-intro {
  position: relative;
  padding-top: 48vw;
}
@media (min-width: 768px) {
  .donation-intro {
    padding-top: clamp(6rem, 14vw, 14rem);
  }
}
.donation-intro__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
}
@media (min-width: 768px) {
  .donation-intro__img {
    width: 85%;
  }
}
.donation-intro__img picture {
  position: relative;
  aspect-ratio: 38/27;
}
@media (min-width: 768px) {
  .donation-intro__img picture {
    aspect-ratio: 40/19;
  }
}

.donation-intro__txt {
  position: relative;
  z-index: 2;
  padding-left: 5%;
  padding-right: 5%;
}
.donation-intro__txt__inner {
  padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 5%, 4em);
  background: #ffffff;
}
@media (min-width: 768px) {
  .donation-intro__txt__inner {
    width: 65%;
    padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 6%, 6em);
  }
}
@media (min-width: 1553px) {
  .donation-intro__txt__inner {
    padding: clamp(2em, 6vw, 6rem) clamp(1.5em, 7%, 8em) clamp(2em, 6vw, 6rem) clamp(1.5em, 6%, 8em);
    width: 60%;
    min-height: 600px;
  }
}
.donation-intro__txt__ttl {
  color: #00A0E9;
  margin-bottom: 0.5em;
  font-size: clamp(19px, 6.79vw, 60px);
  font-weight: 700;
}
@media (min-width: 768px) {
  .donation-intro__txt__ttl {
    font-size: clamp(30px, 3.91vw, 60px);
  }
}
@media (min-width: 1728px) {
  .donation-intro__txt__ttl {
    font-size: 4.13rem;
  }
}
@media (min-width: 768px) {
  .donation-intro__txt__txt {
    font-size: clamp(13px, 1.69vw, 22px);
  }
}
@media (min-width: 1553px) {
  .donation-intro__txt__txt {
    font-size: 1.38rem;
  }
}
.donation-intro__txt__txt strong {
  color: #00A0E9;
  font-size: clamp(11px, 3.93vw, 40px);
  line-height: 1.4;
  font-weight: 700;
}
@media (min-width: 768px) {
  .donation-intro__txt__txt strong {
    font-size: clamp(18px, 2.34vw, 40px);
  }
}
@media (min-width: 1553px) {
  .donation-intro__txt__txt strong {
    font-size: 1.6em;
  }
}

.donation-list {
  padding-top: clamp(3rem, 4vw, 4rem);
  padding-bottom: clamp(9rem, 12vw, 12rem);
}
.donation-list__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .donation-list__list {
    flex-direction: row;
  }
}
@media (min-width: 576px) {
  .donation-list__list {
    flex-direction: column;
  }
}
.donation-list__list > * {
  width: 100%;
}
@media (min-width: 768px) {
  .donation-list__list > * {
    width: 50%;
  }
}
.donation-list__list > *:nth-of-type(odd) {
  background: #EEF5FA;
}
.donation-list__list > *:nth-of-type(even) {
  background: #F6F2D7;
}
.donation-list .btn-wrap {
  padding-top: clamp(3rem, 4vw, 4rem);
}

.donation-list__box {
  padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4em);
}
@media (min-width: 768px) {
  .donation-list__box {
    padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4em) clamp(2em, 4vw, 4em);
  }
}
.donation-list__box__ttl {
  position: relative;
  text-align: center;
  padding-bottom: 1em;
  margin-bottom: 1.5em;
}
.donation-list__box__ttl::before {
  position: absolute;
  left: calc(50% - 1.5em);
  bottom: 0;
  content: "";
  width: 3em;
  height: 3px;
  background: #00A0E9;
  border-radius: 999px;
}
.donation-list__box__ttl b {
  display: block;
  margin-bottom: 0.1em;
  font-size: clamp(14px, 5vw, 36px);
  font-weight: 700;
}
@media (min-width: 768px) {
  .donation-list__box__ttl b {
    font-size: clamp(18px, 2.34vw, 48px);
  }
}
.donation-list__box__ttl em {
  display: block;
  font-style: normal;
  color: #00A0E9;
  font-size: clamp(8px, 2.86vw, 20px);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
@media (min-width: 768px) {
  .donation-list__box__ttl em {
    font-size: clamp(10px, 1.3vw, 24px);
  }
}
.donation-list__box__txt {
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .donation-list__box__txt {
    font-size: 1.14em;
  }
}

.donation-able {
  position: relative;
  padding-top: clamp(4rem, 9vw, 9rem);
}
.donation-able__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
}
@media (min-width: 1176px) {
  .donation-able__bg {
    min-height: 540px;
  }
}
.donation-able__bg__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.donation-able__bg__inner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.donation-able__inner {
  position: relative;
  z-index: 2;
}
.donation-able__ttl {
  color: #ffffff;
  text-align: center;
  margin-bottom: clamp(1.5em, 6vw, 3em);
  font-size: calc(1.25rem + 36 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .donation-able__ttl {
    font-size: 3.63rem;
  }
}
.donation-able__list {
  display: grid;
}
@media (min-width: 768px) {
  .donation-able__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem 2%;
  }
}
@media (min-width: 1176px) {
  .donation-able__list {
    gap: 3rem 5%;
  }
}
@media (max-width: 767px) {
  .donation-able__list {
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}

.donation-able__box {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  row-gap: 0;
}
.donation-able__box__ttl {
  text-align: center;
  color: #ffffff;
  background: #054497;
  padding: clamp(1em, 1.5vw, 2em) clamp(1.5em, 6%, 3em);
  font-size: clamp(13px, 4.64vw, 22px);
}
@media (min-width: 768px) {
  .donation-able__box__ttl {
    font-size: 1.75vw;
  }
}
@media (min-width: 1553px) {
  .donation-able__box__ttl {
    font-size: 1.5rem;
  }
}
.donation-able__box__ttl span {
  display: grid;
  grid-template-rows: subgrid;
  align-items: center;
  height: 100%;
}
.donation-able__box__txt {
  padding: clamp(1.5em, 1.5vw, 2em) clamp(1.5em, 6%, 4em);
  background: #ffffff;
  border-left: 1px solid #C9CED2;
  border-right: 1px solid #C9CED2;
  border-bottom: 1px solid #C9CED2;
  font-size: 1em;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .donation-able__box__txt {
    font-size: 1em;
  }
}

/*--------------------------------------------------------------------/
	benefits
/--------------------------------------------------------------------*/
.benefits-intro {
  position: relative;
  padding-bottom: clamp(3rem, 6vw, 6rem);
}
.benefits-intro .l-cont_xxxl {
  position: relative;
  padding-bottom: clamp(3rem, 6vw, 6rem);
}
.benefits-intro .l-cont_xxxl::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 60%;
  background: #00A0E9;
}
.benefits-intro__inner {
  position: relative;
  max-width: 1200px;
  padding: clamp(1.5em, 6vw, 6em) clamp(1.5em, 5%, 3em);
  background: #ffffff;
  z-index: 2;
  border: 1px solid #C9CED2;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .benefits-intro__inner {
    padding: clamp(1.5em, 6vw, 6em) clamp(3em, 8%, 6em);
  }
}
.benefits-intro__ttl {
  text-align: center;
  margin-bottom: clamp(1.5em, 2vw, 2em);
  font-weight: 700;
  font-size: calc(1.14rem + 10 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .benefits-intro__ttl {
    font-size: 1.88rem;
  }
}
.benefits-intro__ttl strong {
  font-size: 1.37em;
  display: inline-block;
  color: #00A0E9;
  padding-bottom: 0.1em;
  border-bottom: 1px solid #00A0E9;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .benefits-intro__txt {
    font-size: 1.14em;
  }
}

.benefits-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, 48%);
  gap: 1em 2%;
  padding-bottom: clamp(5rem, 9vw, 9rem);
}
@media (min-width: 768px) {
  .benefits-nav {
    grid-template-columns: repeat(auto-fit, 23.5%);
  }
}
.benefits-nav li a {
  row-gap: 0.5em;
  border-bottom: 3px solid #00A0E9;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.benefits-nav li a:hover {
  border-color: #008bf5;
}
.benefits-nav li a:hover span {
  color: #008bf5;
}
.benefits-nav li a:hover i {
  color: #008bf5;
}
.benefits-nav li a span {
  text-align: center;
  color: #222222;
  transition: all 0.3s ease 0s;
  line-height: 1.2;
  font-weight: 700;
}
@media (min-width: 768px) {
  .benefits-nav li a span {
    font-size: calc(0.6rem + 5 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .benefits-nav li a span {
    font-size: 1.25em;
  }
}
.benefits-nav li a i {
  font-size: 3em;
  transition: all 0.3s ease 0s;
  line-height: 1;
}
@media (min-width: 768px) {
  .benefits-nav li a i {
    font-size: 3vw;
  }
}
@media (min-width: 1553px) {
  .benefits-nav li a i {
    font-size: 3em;
  }
}

.benefits-list {
  row-gap: clamp(6rem, 9vw, 9rem);
  display: flex;
  flex-direction: column;
}

.benefits-box {
  position: relative;
}
.benefits-box__inner {
  position: relative;
  background: #EEF5FA;
  padding: clamp(2em, 6vw, 4em) clamp(1.5em, 5%, 3em) clamp(2em, 2vw, 3em);
}
@media (min-width: 768px) {
  .benefits-box__inner {
    padding: clamp(2em, 3vw, 3.5em) clamp(1.5em, 5%, 6em) clamp(2em, 4vw, 4em);
  }
}
@media (min-width: 1176px) {
  .benefits-box__inner {
    padding: clamp(2em, 3vw, 3.5em) clamp(3em, 8%, 6em) clamp(2em, 4vw, 4em);
  }
}
.benefits-box__ttl {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: clamp(2em, 3vw, 3em);
  text-align: center;
}
.benefits-box__ttl::before {
  position: absolute;
  bottom: 0;
  left: calc(50% - 1.5em);
  content: "";
  width: 3em;
  height: 3px;
  background: #00A0E9;
  border-radius: 999px;
}
.benefits-box__ttl span {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: clamp(60px, 20%, 98px);
}
.benefits-box__ttl b {
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .benefits-box__ttl b {
    font-size: 2rem;
  }
}
.benefits-box__toptxt {
  width: fit-content;
  margin-bottom: clamp(2em, 3vw, 3em);
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .benefits-box__toptxt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .benefits-box__toptxt {
    font-size: 1.14em;
  }
}
.benefits-box__bottomtxt {
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .benefits-box__bottomtxt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .benefits-box__bottomtxt {
    font-size: 1.14em;
  }
}

.benefits-box__individual__grid {
  display: grid;
  margin-bottom: clamp(1.5em, 2vw, 2.5em);
}
@media (min-width: 768px) {
  .benefits-box__individual__grid {
    grid-template-columns: 1fr 10% 1fr;
  }
}
@media (max-width: 767px) {
  .benefits-box__individual__grid {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-left {
    grid-column: 1/2;
  }
}
.benefits-box__individual__grid .is-or {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75em;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-or {
    grid-column: 2/3;
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .benefits-box__individual__grid .is-or {
    font-size: 2.13rem;
  }
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-right {
    grid-column: 3/4;
  }
}
.benefits-box__individual__grid .is-box {
  background: #ffffff;
  padding: 1.5em 2em 2em;
  height: 100%;
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-box {
    padding: 1.5em 1em;
  }
}
@media (min-width: 1553px) {
  .benefits-box__individual__grid .is-box {
    padding: 1.5em 2em 2em;
  }
}
.benefits-box__individual__grid .is-box h3 {
  text-align: center;
  color: #00A0E9;
  margin-bottom: 0.5em;
  font-size: clamp(14px, 5vw, 24px);
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-box h3 {
    font-size: clamp(16px, 2.08vw, 28px);
  }
}
.benefits-box__individual__grid .is-box .is-price {
  background: #F6F6F6;
  padding: 0.5em 1.25em;
  text-align: center;
  margin-bottom: 1em;
  font-weight: 700;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .benefits-box__individual__grid .is-box .is-price {
    font-size: 1.5vw;
  }
}
@media (min-width: 1553px) {
  .benefits-box__individual__grid .is-box .is-price {
    font-size: 1.5rem;
  }
}
.benefits-box__individual__grid .is-box .is-price b {
  color: #00A0E9;
  font-size: 1.14em;
  font-weight: 600;
}
.benefits-box__individual__grid .is-box ul {
  font-size: 0.9em;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
}
.benefits-box__individual .is-text {
  margin-bottom: clamp(2em, 3vw, 3em);
  text-align: center;
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .benefits-box__individual .is-text {
    font-size: 1.5rem;
  }
}

.benefits-box__corporation {
  margin-bottom: clamp(2em, 3vw, 3em);
  row-gap: clamp(0.5rem, 1vw, 1rem);
  display: flex;
  flex-direction: column;
}
.benefits-box__corporation .is-box {
  background: #ffffff;
  height: 100%;
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(2em, 2vw, 3em);
}
@media (min-width: 768px) {
  .benefits-box__corporation .is-box {
    padding: clamp(2em, 3vw, 3.5em) clamp(1.5em, 5%, 6em) clamp(2em, 4vw, 4em);
  }
}
@media (min-width: 1176px) {
  .benefits-box__corporation .is-box {
    padding: clamp(2em, 3vw, 3.5em) clamp(3em, 8%, 6em) clamp(2em, 4vw, 4em);
  }
}
.benefits-box__corporation .is-box h3 {
  text-align: center;
  color: #00A0E9;
  margin-bottom: 1em;
  font-size: clamp(12px, 4.29vw, 28px);
}
.benefits-box__corporation .is-box .is-list {
  row-gap: clamp(1em, 2vw, 2em);
  display: flex;
  flex-direction: column;
}
.benefits-box__corporation .is-box .is-item {
  background: #F6F6F6;
  padding: 1.5em 1.25em;
  text-align: center;
  line-height: 1.5;
}
.benefits-box__corporation .is-box .is-item h4 {
  color: #054497;
  display: inline-block;
  padding: 0.5em 1em;
  background: #ffffff;
  margin-bottom: 0.75em;
  font-weight: 700;
  font-size: clamp(10px, 3.57vw, 24px);
}
.benefits-box__corporation .is-box .is-item p {
  font-weight: 700;
  font-size: clamp(9.5px, 3.39vw, 22px);
}
.benefits-box__corporation .is-box ul {
  margin-top: 1.5em;
  font-size: 0.9em;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
}
.benefits-box__corporation .is-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5em;
  color: #222222;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
@media (min-width: 1553px) {
  .benefits-box__corporation .is-plus {
    font-size: 5.63rem;
  }
}

/*--------------------------------------------------------------------/
	reports
/--------------------------------------------------------------------*/
.reports-list {
  padding-top: clamp(2em, 3vw, 3rem);
}

.report-item__ttl {
  text-align: center;
  padding-bottom: 0.5em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid #C9CED2;
  font-size: 1.25em;
  font-weight: 700;
}
.report-item__pdf a {
  padding: 1.25em 4% 1.25em 4.5em;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #222222;
  background: #EEF5FA;
  transition: all 0.3s ease 0s;
  line-height: 1.4;
  text-decoration: none !important;
  font-weight: 500;
}
.report-item__pdf a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.report-item__pdf a::before {
  position: absolute;
  left: 1.25em;
  top: 0.8em;
  font-size: 1.6em;
  color: #00A0E9;
  transition: all 0.3s ease 0s;
}
.report-item__pdf a:hover {
  background: #008bf5;
  color: #ffffff;
}
.report-item__pdf a:hover::before {
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/
.contact-intro {
  position: relative;
  padding-top: clamp(3rem, 6vw, 6rem);
  margin-bottom: clamp(2rem, 6vw, 6rem);
}
.contact-intro__txt {
  font-weight: 600;
}
@media (min-width: 768px) {
  .contact-intro__txt {
    text-align: center;
    font-size: 1.14em;
  }
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
.contact-form__ttl {
  color: #00A0E9;
  text-align: center;
  margin-bottom: clamp(2rem, 3vw, 3rem);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: calc(1.25rem + 10 * (100vw - 280px) / 1300);
  letter-spacing: 0;
}
@media (min-width: 1553px) {
  .contact-form__ttl {
    font-size: 2.25rem;
  }
}

.contact-mail {
  border: 1px solid #979DA4;
  padding: 1.5rem 6%;
}
@media (min-width: 1176px) {
  .contact-mail {
    padding: clamp(1.5rem, 3vw, 3rem) 10% clamp(2rem, 3.5vw, 3rem);
  }
}
.contact-mail__ttl {
  position: relative;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1300);
  font-weight: 700;
  text-align: center;
  padding-bottom: 0.75em;
  margin-bottom: clamp(1rem, 2vw, 2rem);
}
@media (min-width: 1553px) {
  .contact-mail__ttl {
    font-size: 1.5rem;
  }
}
.contact-mail__ttl::before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 3px;
  bottom: 0;
  left: calc(50% - 2rem);
  border-radius: 2px;
  background: #00A0E9;
}

.contact-privacy {
  border: 1px solid #979DA4;
}
@media (min-width: 1176px) {
  .contact-privacy {
    padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
    margin-top: 1.5rem;
    overflow-y: scroll;
    max-height: 300px;
    scrollbar-width: thin;
    scrollbar-color: #C9CED2 #ffffff;
  }
  .contact-privacy::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy::-webkit-scrollbar-track {
    background: #F6F6F6;
  }
  .contact-privacy::-webkit-scrollbar-thumb {
    background: #C9CED2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #ffffff;
  }
}
@media (min-width: 1176px) {
  .contact-privacy__inner {
    padding: 0 5%;
  }
}
.contact-privacy__ttl {
  text-align: center;
  padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
  position: relative;
  font-size: calc(1.14rem + 6 * (100vw - 376px) / 1204);
  font-weight: 700;
}
@media (min-width: 1176px) {
  .contact-privacy__ttl {
    margin-bottom: clamp(1rem, 2vw, 2rem);
  }
  .contact-privacy__ttl::before {
    content: "";
    position: absolute;
    width: 4rem;
    height: 3px;
    bottom: 0;
    left: calc(50% - 2rem);
    border-radius: 2px;
    background: #00A0E9;
  }
  .contact-privacy__ttl .privacy-btn {
    display: none;
  }
}
@media (min-width: 1553px) {
  .contact-privacy__ttl {
    font-size: 1.5rem;
  }
}
@media (max-width: 1175px) {
  .contact-privacy__ttl {
    padding: 1.5rem 6%;
    position: relative;
  }
  .contact-privacy__ttl .privacy-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 16px);
    right: 1rem;
    width: 32px;
    height: 32px;
    transition: all 0.3s ease 0s;
  }
  .contact-privacy__ttl .privacy-btn i {
    font-size: 2rem;
    line-height: 1;
    color: #979DA4;
  }
  .contact-privacy__ttl .privacy-btn.is-active {
    transform: rotate(180deg);
  }
}
@media (max-width: 1175px) {
  .contact-privacy__txt {
    padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
  }
}
.contact-privacy .txt {
  font-size: 0.94em;
}

.contact-table {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 2rem;
  line-height: 1.2;
  position: relative;
}
.contact-table th, .contact-table td {
  text-align: left;
}
@media (min-width: 1176px) {
  .contact-table th, .contact-table td {
    display: table-cell;
    padding: 1rem 0 1.5rem;
  }
}
@media (max-width: 1175px) {
  .contact-table th, .contact-table td {
    display: block;
    padding: 0;
  }
}
.contact-table th {
  vertical-align: top;
  font-weight: 600;
}
@media (min-width: 1176px) {
  .contact-table th {
    width: 30%;
    line-height: 1.5;
  }
}
.contact-table th span {
  color: #ffffff;
  margin-left: 0.3rem;
  padding: 0.2em 0.75em;
  font-size: 0.8125em;
}
@media (min-width: 1176px) {
  .contact-table td {
    width: auto;
  }
}
@media (max-width: 1175px) {
  .contact-table td {
    padding: 0.5em 0 2em;
  }
}
.contact-table textarea {
  width: 100%;
}

.contact-form__certification {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2em;
  row-gap: 0.5em;
}
.contact-form__certification dt {
  font-size: 1.07em;
  font-weight: 700;
}
.contact-form__certification dt span {
  color: #ffffff;
  margin-left: 0.3rem;
  padding: 0.2em 0.75em;
  font-size: 0.8125em;
}

input,
select {
  width: auto;
}
input.long,
select.long {
  width: 100%;
}
input.middle,
select.middle {
  width: 100%;
}
@media (min-width: 768px) {
  input.middle,
  select.middle {
    width: 70%;
  }
}
input.short,
select.short {
  width: 100%;
}
@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}
input.mini,
select.mini {
  width: 10em;
}

[type=button],
[type=submit] {
  -webkit-appearance: none;
  font-family: inherit;
}

.form-btn input {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-btn .chack-btn {
  position: relative;
}
.form-btn .chack-btn:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-btn .chack-btn::before {
  position: absolute;
  top: 1.25em;
  right: 0.75em;
  content: "";
  width: 32px;
  height: 32px;
  background: #ffffff;
  z-index: 1;
  border-radius: 50%;
}
.form-btn .chack-btn::after {
  position: absolute;
  top: 1.75em;
  right: 1.1em;
  color: #00A0E9;
  font-size: 1em;
  z-index: 2;
}
.form-btn .chack-btn:hover::after {
  color: #008bf5;
}
.form-btn .chack-btn:hover input,
.form-btn .chack-btn:hover a {
  color: #ffffff;
  background-color: #008bf5;
}
.form-btn .chack-btn input,
.form-btn .chack-btn a {
  display: block;
  color: #ffffff;
  background-color: #00A0E9;
  padding: 1.25em 4%;
  width: 100%;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-size: 1.13rem;
  letter-spacing: 0.1em;
  border-radius: 999px;
}
.form-btn .back-btn {
  position: relative;
}
.form-btn .back-btn:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-btn .back-btn::before {
  position: absolute;
  top: 1.25em;
  right: 0.75em;
  content: "";
  width: 32px;
  height: 32px;
  background: #ffffff;
  z-index: 1;
  border-radius: 50%;
}
.form-btn .back-btn::after {
  position: absolute;
  top: 1.75em;
  right: 1.1em;
  color: #091934;
  font-size: 1em;
  z-index: 2;
}
.form-btn .back-btn:hover::after {
  color: #008bf5;
}
.form-btn .back-btn:hover input,
.form-btn .back-btn:hover a {
  color: #ffffff;
  background-color: #008bf5;
}
.form-btn .back-btn input,
.form-btn .back-btn a {
  display: block;
  color: #ffffff;
  background-color: #091934;
  border: 1px solid;
  padding: 1.25em 4%;
  width: 100%;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-size: 1.13rem;
  letter-spacing: 0.1em;
  border-radius: 999px;
}
.form-btn .sub-btn {
  position: relative;
}
.form-btn .sub-btn:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-btn .sub-btn::before {
  position: absolute;
  top: 1.25em;
  right: 0.75em;
  content: "";
  width: 32px;
  height: 32px;
  background: #ffffff;
  z-index: 1;
  border-radius: 50%;
}
.form-btn .sub-btn::after {
  position: absolute;
  top: 1.75em;
  right: 1.1em;
  color: #00A0E9;
  font-size: 1em;
  z-index: 2;
}
.form-btn .sub-btn:hover::after {
  color: #008bf5;
}
.form-btn .sub-btn:hover input,
.form-btn .sub-btn:hover a {
  color: #ffffff;
  background-color: #008bf5;
}
.form-btn .sub-btn input,
.form-btn .sub-btn a {
  display: block;
  color: #ffffff;
  background-color: #00A0E9;
  padding: 1.25em 4%;
  width: 100%;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-size: 1.13rem;
  letter-spacing: 0.1em;
  border-radius: 999px;
}

/* radio-btn */
.wpcf7-radio {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.wpcf7-radio .wpcf7-list-item {
  display: block;
  margin: 0 !important;
}
.wpcf7-radio label {
  cursor: pointer;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio] + .wpcf7-list-item-label {
  vertical-align: top;
  font-size: 1.07em;
}
input[type=radio] + .wpcf7-list-item-label:before {
  content: "";
  background: #fdfdfd;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: relative;
  top: -0.1em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
input[type=radio]:checked + .wpcf7-list-item-label:before {
  background-color: #00A0E9;
  box-shadow: inset 0 0 0 5px #F6F6F6;
}
input[type=radio]:focus + .wpcf7-list-item-label:before {
  outline: none;
  border-color: #00A0E9;
}
input[type=radio]:disabled + .wpcf7-list-item-label:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #fdfdfd;
}
input[type=radio] + .wpcf7-list-item-label:empty:before {
  margin-right: 0;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
input[type=checkbox] {
  display: none;
}

.wpcf7-checkbox {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.wpcf7-checkbox .wpcf7-list-item {
  display: block;
  margin: 0 !important;
}
.wpcf7-checkbox label {
  cursor: pointer;
}

.check-box label {
  position: relative;
  cursor: pointer;
}
.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: 0em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 1;
}
.check-box .wpcf7-list-item-label {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
  font-size: 1.07em;
}
.check-box .wpcf7-list-item-label::before, .check-box .wpcf7-list-item-label::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #00A0E9;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 2;
}
.check-box .wpcf7-list-item-label::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}
.check-box .wpcf7-list-item-label::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .wpcf7-list-item-label,
.wpcf7-list-item-label.checked {
  border-color: #00A0E9;
}
input[type=checkbox]:checked + .wpcf7-list-item-label::after,
.wpcf7-list-item-label.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}
input[type=checkbox]:checked + .wpcf7-list-item-label::before,
.wpcf7-list-item-label.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #ffffff;
}

.wpcf7-not-valid-tip {
  font-size: 0.875em !important;
  margin-top: 0.7em;
}

div.wpcf7 .wpcf7-spinner {
  display: none !important;
}

/* form-parts
----------------------------------------------------------------*/
select, textarea, input {
  background-color: #fdfdfd;
  padding: 1em;
  border: 1px solid #979DA4;
  line-height: 1.5;
}
select:focus, textarea:focus, input:focus {
  background-color: #fff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input:-ms-input-placeholder, input:-moz-placeholder, input::-webkit-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.blog-intro {
  padding: clamp(1.5rem, 2vw, 2rem) 5%;
  margin-bottom: clamp(3rem, 6vw, 6rem);
  background: #F6F6F6;
}
@media (min-width: 1176px) {
  .blog-intro {
    padding: clamp(2rem, 3vw, 3rem) 8%;
    text-align: center;
  }
}

@media (min-width: 1176px) {
  .contents-flex {
    display: flex;
    justify-content: space-between;
  }
}

.contents-left {
  width: 100%;
}
@media (min-width: 1176px) {
  .contents-left {
    width: calc(96% - 320px);
  }
}

/*-------------------------------------------------------/
//Easy Table of Contents
--------------------------------------------------------*/
#ez-toc-container {
  border-width: 1px;
  border-radius: 0;
  margin-bottom: clamp(3em, 6vw, 6em) !important;
  border-color: #C9CED2 !important;
}

/* 目次タイトルの背景色と余白調整 */
#ez-toc-container .ez-toc-title-container,
#ez-toc-container {
  display: block;
  background: #00A0E9;
  margin: 0;
  padding: 0.75em 2em 0.75em 1.5em;
}

/* 目次タイトルの余白とカラー */
div#ez-toc-container .ez-toc-title {
  display: block;
  color: #ffffff !important;
}

/* 目次内の上下余白調整 */
#ez-toc-container .ez-toc-list {
  padding: 1em 1.5em;
}

.ez-toc-js-icon-con {
  border: none !important;
}

/* 見出しの下線 */
#ez-toc-container .ez-toc-list li {
  display: block;
  border-bottom: 1px dashed #C9CED2;
}
#ez-toc-container .ez-toc-list li a {
  display: block;
  line-height: 1.5;
  padding: 0.5em 0;
}

#ez-toc-container .ez-toc-list li:last-child {
  border: 0;
}

/* 目次開閉ボタン位置調整 */
.ez-toc-title-toggle {
  position: absolute;
  top: 0.65em;
  right: 2.5%;
}

/* 目次開閉ボタンデザイン */
#ez-toc-container .ez-toc-js-icon-con {
  border: none;
  background: #F6F6F6;
  border-radius: 0;
  width: 33px;
}

/* スマホ向けのサイズと位置調整 */
@media (max-width: 500px) {
  /* 目次タイトル */
  div#ez-toc-container .ez-toc-title {
    font-size: 15px;
    text-align: center;
    padding-left: initial;
    padding: 5px 0;
  }
  /* 開閉ボタンの位置 */
}
.wp-block-heading .ez-toc-section {
  padding-top: calc(1em + 64px);
  margin-top: calc(-1em - 64px);
}
@media (min-width: 1553px) {
  .wp-block-heading .ez-toc-section {
    padding-top: 4em;
    margin-top: -4em;
  }
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1176px) {
  .aside-cont.is-sticky {
    position: sticky;
    top: 120px;
    left: 0;
  }
}
.aside-cont__inner {
  row-gap: clamp(2rem, 4vw, 4rem);
  display: flex;
  flex-direction: column;
}

.aside-box__ttl {
  padding: 0.3rem 0 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #C9CED2;
  font-weight: 500;
  font-size: 1.25em;
}
@media (min-width: 1176px) {
  .aside-box__ttl {
    font-size: calc(1.14rem + 4 * (100vw - 1080px) / 500);
  }
}
@media (min-width: 1553px) {
  .aside-box__ttl {
    font-size: 1.38rem;
  }
}
.aside-box__list.is-category {
  display: flex;
  flex-direction: column;
}
.aside-box__list.is-category li {
  border-bottom: 1px solid #E5E9EC;
}
.aside-box__list.is-category li a {
  color: #222222;
  text-decoration: none !important;
  display: flex;
  column-gap: 0.5em;
  padding: 0.75em 0.5em 0.75em 0.2em;
  position: relative;
}
.aside-box__list.is-category li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #00A0E9;
}
.aside-box__list.is-category li a:hover::before {
  width: 100%;
}
.aside-box__list.is-category li a .is-circle {
  padding-top: 0.5em;
  font-size: 0.7em;
  text-decoration: none !important;
}
.aside-box__list.is-category li a .is-text {
  font-weight: 500;
}

.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts__list li {
  border-bottom: 1px solid #E5E9EC;
}
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.wp-block-latest-posts__list li a {
  color: #222222;
  text-decoration: none !important;
  display: block;
  padding: 0.75em 0.5em 0.75em 0;
  position: relative;
}
@media (min-width: 1176px) {
  .wp-block-categories-list li a,
  .wp-block-archives-list li a,
  .wp-block-latest-posts__list li a {
    font-size: 0.94em;
  }
}
.wp-block-categories-list li a::before,
.wp-block-archives-list li a::before,
.wp-block-latest-posts__list li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #00A0E9;
}
.wp-block-categories-list li a:hover::before,
.wp-block-archives-list li a:hover::before,
.wp-block-latest-posts__list li a:hover::before {
  width: 100%;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #222222;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3em;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.875em;
  color: #979DA4;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #00A0E9;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:hover {
  color: #00A0E9;
}
.tag-cloud-link:hover::before {
  color: #00A0E9;
}
.tag-cloud-link:hover::after {
  width: 100%;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #C9CED2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}
.wp-calendar-table caption {
  padding: 0.3rem 2%;
  font-weight: 500;
}
.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}
.wp-calendar-table td {
  text-align: center;
  background: #F6F6F6;
  line-height: 2;
  border: 2px solid #ffffff;
  font-size: 0.94em;
}
.wp-calendar-table td a {
  background-color: #00A0E9;
  color: #ffffff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}
.wp-calendar-table td a:hover {
  background-color: #054497;
}
.wp-calendar-table td.pad {
  background: transparent;
}
.wp-calendar-table td#today {
  background: #979DA4;
  color: #ffffff;
}
.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}
.wp-calendar-nav a {
  display: block;
  color: #222222;
  padding: 0.2em 1rem;
  background-color: #F6F6F6;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.wp-calendar-nav a:hover {
  background-color: #00A0E9;
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid #C9CED2 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wp-block-search__input {
  height: 44px;
  padding: 0 10px !important;
  outline: 0;
  background: #ffffff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}

.wp-block-search__button {
  margin: 0;
  height: 44px !important;
  width: 50px !important;
  padding: 2px !important;
  background: #00A0E9 !important;
  font-size: 18px !important;
  vertical-align: bottom !important;
  color: #ffffff !important;
  transition: all 0.3s ease 0s;
  border: none !important;
  text-align: center;
  cursor: pointer !important;
}
.wp-block-search__button:hover {
  color: #ffffff !important;
  background-color: #008bf5 !important;
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives-dropdown {
  position: relative;
  margin-top: 1em;
}
.wp-block-archives-dropdown:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wp-block-archives-dropdown::after {
  position: absolute;
  top: 0.75em;
  right: 0.5em;
  color: #979DA4;
  line-height: 1;
  font-size: 1.31rem;
}
.wp-block-archives-dropdown > label {
  display: none !important;
}
.wp-block-archives-dropdown select {
  position: relative;
  padding-right: 1.5em;
  width: 100% !important;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  background: #ffffff;
}
@media (min-width: 1296px) {
  .main-view {
    padding-top: 100px;
  }
}
@media (min-width: 1553px) {
  .main-view {
    padding-top: 125px;
  }
}
.main-view__inner {
  position: relative;
  height: 220px;
  z-index: 1;
  background: url(../img/lv2/lv2-bg_sp.jpg) center center/cover no-repeat;
}
.main-view__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transform-origin: top right;
  width: 53%;
  height: 100%;
  background: rgba(0, 160, 233, 0.7);
  transform: skew(-15deg);
}
@media (min-width: 768px) {
  .main-view__inner {
    height: 30vw;
    background: url(../img/lv2/lv2-bg_tb.jpg) center center/cover no-repeat;
  }
}
@media (min-width: 1176px) {
  .main-view__inner {
    height: 20vw;
  }
}
@media (min-width: 1553px) {
  .main-view__inner {
    height: 310px;
    background: url(../img/lv2/lv2-bg_pc.jpg) center center/cover no-repeat;
  }
}
@media (min-width: 1921px) {
  .main-view__inner {
    height: 16.14vw;
  }
}
.main-view .l-cont {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
}

.main-view__ttl {
  position: relative;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  line-height: 1.4;
}
.main-view__ttl > em {
  position: relative;
  display: block;
  font-style: normal;
  text-transform: capitalize;
  padding-bottom: 0.2em;
  margin-bottom: 0.2em;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.03em;
  font-size: calc(1.75rem + 46 * (100vw - 280px) / 1300);
  font-weight: 600;
}
@media (min-width: 1553px) {
  .main-view__ttl > em {
    font-size: 5rem;
  }
}
.main-view__ttl > em::before {
  position: absolute;
  display: block;
  bottom: 0;
  left: calc(50% - 0.5em);
  content: "";
  width: 1em;
  height: 2px;
  background: #ffffff;
  border-radius: 999px;
}
.main-view__ttl > span {
  display: block;
  line-height: 1.2;
  font-size: calc(1rem + 14 * (100vw - 280px) / 1300);
  font-weight: 800;
}
@media (min-width: 1553px) {
  .main-view__ttl > span {
    font-size: 1.88rem;
  }
}
.main-view__ttl > span.is-404 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: calc(2.5rem + 32 * (100vw - 280px) / 1300);
  letter-spacing: 0.02em;
}
@media (min-width: 1553px) {
  .main-view__ttl > span.is-404 {
    font-size: 4.5rem;
  }
}
.main-view__ttl > span.is-category {
  display: inline-block;
  margin-top: 0.1em;
  letter-spacing: 0;
  font-weight: 700;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .main-view__ttl > span.is-category {
    font-size: 1.75rem;
  }
}
.main-view__ttl > span.is-category span {
  display: block;
}
.main-view__ttl > span.is-category .is-term {
  margin-top: 1em;
  display: inline-block;
  background-color: #F6F6F6;
  color: #00A0E9;
  text-align: center;
  line-height: 1.4;
  padding: 0.4em 1.25em;
  font-size: 0.85em;
  letter-spacing: 0.05em;
  border-radius: 999px;
  font-weight: 600;
}
@media (min-width: 1553px) {
  .main-view__ttl > span.is-category .is-term {
    font-size: 1rem;
  }
}
.main-view__ttl > span.is-archive {
  margin-top: 0.3em;
  font-weight: 700;
  font-size: calc(1.25rem + 20 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .main-view__ttl > span.is-archive {
    font-size: 2.5rem;
  }
}

.main-view__txt {
  color: #ffffff;
  row-gap: 0.75em;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  flex-direction: column;
}
.main-view__txt__sub {
  position: relative;
  width: 100%;
  padding-bottom: 0.75em;
  font-weight: 400;
  line-height: 1.4;
}
.main-view__txt__sub::after {
  position: absolute;
  bottom: 0;
  left: calc(50% - 1.5em);
  content: "";
  width: 3em;
  height: 2px;
  background: #ffffff;
}
.main-view__txt__sub em {
  display: block;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-size: calc(1.25rem + 20 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .main-view__txt__sub em {
    font-size: 3rem;
  }
}
.main-view__txt__sub span {
  display: block;
  padding-left: 0.2em;
  font-size: calc(0.9rem + 6 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .main-view__txt__sub span {
    font-size: 1.25rem;
  }
}
.main-view__txt__ttl {
  position: relative;
  width: 100%;
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .main-view__txt__ttl {
    font-size: 2.25rem;
  }
}
.main-view__txt__category li {
  display: inline-block;
  margin-right: 0.2em;
}
.main-view__txt__category .category-item {
  background-color: #F6F6F6;
  color: #00A0E9;
  text-align: center;
  line-height: 1.4;
  padding: 0.4em 1.25em;
  font-size: 0.85em;
  letter-spacing: 0.05em;
  border-radius: 999px;
  font-weight: 600;
}
@media (min-width: 1553px) {
  .main-view__txt__category .category-item {
    font-size: 1rem;
  }
}

.common-flex {
  width: 100%;
  position: relative;
  display: flex;
}
@media (min-width: 1176px) {
  .common-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .common-flex {
    flex-direction: column;
    row-gap: clamp(3rem, 5vw, 5rem);
  }
}
@media (min-width: 1176px) {
  .common-flex.is-right {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1176px) {
  .common-flex__main {
    width: 72%;
  }
}
@media (min-width: 1553px) {
  .common-flex__main {
    width: 74.16%;
  }
}
.common-flex__aside {
  position: relative;
}
@media (min-width: 1176px) {
  .common-flex__aside {
    width: 25%;
  }
}
@media (min-width: 1553px) {
  .common-flex__aside {
    width: 20.83%;
  }
}
@media (max-width: 1175px) {
  .common-flex__aside {
    width: 100%;
  }
}

.common-flex__list {
  display: flex;
}
.common-flex__list.is-col1 {
  flex-direction: column;
  row-gap: clamp(1.5rem, 3vw, 3rem);
}
@media (min-width: 576px) {
  .common-flex__list.is-col1 {
    row-gap: clamp(1.5rem, 2vw, 2rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col2 {
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col2 {
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 {
    column-gap: 4.5%;
  }
}
@media (max-width: 575px) {
  .common-flex__list.is-col2 {
    flex-direction: column;
    row-gap: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col2 > * {
    flex-basis: 48.5%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 > * {
    flex-basis: 47.75%;
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col3 {
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col3 {
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col3 {
    column-gap: 1.25%;
  }
}
@media (min-width: 1553px) {
  .common-flex__list.is-col3 {
    column-gap: 2.8%;
  }
}
@media (max-width: 575px) {
  .common-flex__list.is-col3 {
    flex-direction: column;
    row-gap: clamp(1.5rem, 3vw, 3rem);
  }
}
@media (min-width: 576px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 48.5%;
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 32%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 32.5%;
  }
}
@media (min-width: 1553px) {
  .common-flex__list.is-col3 > * {
    flex-basis: 31.46%;
  }
}

/*--------------------------------------------------------------------/
	404 page
/--------------------------------------------------------------------*/
.cont-404__inner {
  background: #ffffff;
  border: 1px solid #C9CED2;
  padding: clamp(2rem, 4vw, 4rem) 5%;
}
.cont-404__ttl {
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
  text-align: center;
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .cont-404__ttl {
    font-size: 1.5rem;
  }
}
.cont-404__ttl span {
  display: inline-block;
  padding-left: 2em;
  position: relative;
}
@media (min-width: 768px) {
  .cont-404__txt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .cont-404__txt {
    font-size: 1.13rem;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont__txt {
  margin-bottom: 4rem;
}
.single-cont__txt::after {
  display: block;
  content: "";
  clear: both;
}
@media (min-width: 768px) {
  .single-cont__txt {
    margin-bottom: 6rem;
  }
}
.single-cont__thumbnail {
  float: right;
  width: 50%;
  margin-left: 6%;
  margin-bottom: 3rem;
}
.single-cont__foot {
  padding-top: clamp(3rem, 5vw, 5rem);
}

.single-cont__tag {
  margin-bottom: 1em;
}
.single-cont__tag a {
  font-size: 0.94em;
  color: #222222;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3em;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-cont__tag a::before {
  position: absolute;
  top: 0.5em;
  left: 0.2rem;
  font-size: 0.875em;
  color: #979DA4;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #00A0E9;
  transition: all 0.3s ease 0s;
}
.single-cont__tag a:hover {
  color: #00A0E9;
}
.single-cont__tag a:hover::before {
  color: #00A0E9;
}
.single-cont__tag a:hover::after {
  width: 100%;
}

.addtoany_shortcode {
  padding-bottom: 1.5em;
}

.single-sub {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .single-sub {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
  }
}

.single-date {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  line-height: 1.4;
  color: #979DA4;
  margin-right: 1rem;
}

/* align */
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
  margin-left: 1.5em;
}

.alignleft {
  float: left;
  margin-right: 1.5em;
}