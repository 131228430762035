@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	donation
/--------------------------------------------------------------------*/
// donation-intro
.donation-intro {
	position: relative;
	padding-top: 48vw;
	@include media-breakpoint-up(md) {
		padding-top: clamp(6rem, 14vw, 14rem);
	}
	&__img {
		position: absolute;
		top: 0;
		right: 0;
		width: 90%;
		@include media-breakpoint-up(md) {
			width: 85%;
		}
		picture {
			position: relative;
			aspect-ratio: 38 / 27;
			@include media-breakpoint-up(md) {
				aspect-ratio: 40 / 19;
			}
		}
	}
}

.donation-intro {
	&__txt {
		position: relative;
		z-index: z-index(module, part02);
		padding-left: map-get($contmargin, sp);
		padding-right: map-get($contmargin, sp);
		&__inner {
			padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 5%, 4em);
			background: $white;
			@include media-breakpoint-up(md) {
				width: 65%;
				padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 6%, 6em);
			}
			@include media-breakpoint-up(lg) {
			}
			@include media-breakpoint-up(xl) {
				padding: clamp(2em, 6vw, 6rem) clamp(1.5em, 7%, 8em) clamp(2em, 6vw, 6rem) clamp(1.5em, 6%, 8em);
				width: 60%;
				min-height: 600px;
			}
		}
		&__ttl {
			color: $main_c;
			margin-bottom: 0.5em;
			@include fs_xxs(19, 60);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				@include fs_md(30, 60);
			}
			@include media-breakpoint-up(xxxl) {
				@include f-size(66);
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				@include fs_md(13, 22);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
			strong {
				color: $main_c;
				@include fs_xxs(11, 40);
				@include line-h(1.4);
				@include f-w(700);
				@include media-breakpoint-up(md) {
					@include fs_md(18, 40);
				}
				@include media-breakpoint-up(xl) {
					font-size: 1.6em;
				}
			}
		}
	}
}

// donation-list
.donation-list {
	padding-top: clamp(3rem, 4vw, 4rem);
	padding-bottom: clamp(9rem, 12vw, 12rem);
	&__list {
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		@include media-breakpoint-up(sm) {
			flex-direction: column;
		}
		& > * {
			width: 100%;
			@include media-breakpoint-up(md) {
				width: 50%;
			}
			&:nth-of-type(odd) {
				background: $bg_c3;
			}
			&:nth-of-type(even) {
				background: $bg_c4;
			}
		}
	}
	.btn-wrap {
		padding-top: clamp(3rem, 4vw, 4rem);
	}
}

.donation-list__box {
	padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4em);
	@include media-breakpoint-up(md) {
		padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4em) clamp(2em, 4vw, 4em);
	}
	&__ttl {
		position: relative;
		text-align: center;
		padding-bottom: 1em;
		margin-bottom: 1.5em;
		&::before {
			position: absolute;
			left: calc(50% - 1.5em);
			bottom: 0;
			content: "";
			width: 3em;
			height: 3px;
			background: $main_c;
			@include radius(999px);
		}
		b {
			display: block;
			margin-bottom: 0.1em;
			@include fs_xxs(14, 36);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				@include fs_md(18, 48);
			}
		}
		em {
			display: block;
			font-style: normal;
			color: $main_c;
			@include fs_xxs(8, 20);
			@include f-w(600);
			@include f-family(font02);
			@include media-breakpoint-up(md) {
				@include fs_md(10, 24);
			}
		}
	}
	&__txt {
		width: fit-content;
		@include m-a;
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			font-size: 1.14em;
		}
	}
}

// donation-able
.donation-able {
	position: relative;
	padding-top: clamp(4rem, 9vw, 9rem);
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 45%;
		@include media-breakpoint-up(lg) {
			min-height: 540px;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 100%;
			@include bg_filter;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__ttl {
		color: $white;
		text-align: center;
		margin-bottom: clamp(1.5em, 6vw, 3em);
		@include f-s_xxs(1.25, 36);
		@include f-w(700);
		@include media-breakpoint-up(xl) {
			@include f-size(58);
		}
	}
	&__list {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
			gap: 2rem 2%;
		}
		@include media-breakpoint-up(lg) {
			gap: 3rem 5%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			align-items: center;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
}

.donation-able__box {
	display: grid;
	grid-template-rows: subgrid;
	grid-row: span 2;
	row-gap: 0;
	&__ttl {
		text-align: center;
		color: $white;
		background: $sub_c;
		padding: clamp(1em, 1.5vw, 2em) clamp(1.5em, 6%, 3em);
		@include fs_xxs(13,22);
		@include media-breakpoint-up(md) {
			font-size: 1.75vw;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		span {
			display: grid;
			grid-template-rows: subgrid;
			align-items: center;
			height: 100%;
		}
	}
	&__txt {
		padding: clamp(1.5em, 1.5vw, 2em) clamp(1.5em, 6%, 4em);
		background: $white;
		border-left: 1px solid $m-gray;
		border-right: 1px solid $m-gray;
		border-bottom: 1px solid $m-gray;
		font-size: 1em;
		@include line-h(1.5);
		@include media-breakpoint-up(md) {
			font-size: 1em;
		}
	}
}
