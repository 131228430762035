@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;

/*====================================================================

lv2.css

=====================================================================*/
@use "_components/breadcrumb"; //パンくずリスト
@use "_lv2/news"; //新着情報
@use "_lv2/about"; //JBC・CSR基金について
@use "_lv2/poverty"; //高校生の貧困について
@use "_lv2/scholarship"; //高校生奨学金制度について
@use "_lv2/recruit"; //採用情報
@use "_lv2/activity"; //活動実績
@use "_lv2/voice"; //卒業生の声
@use "_lv2/donation"; //毎月・都度寄付について
@use "_lv2/benefits"; //税制優遇について
@use "_lv2/reports"; //決算・事業報告
@use "_lv2/contact"; //お問い合わせ
@use "_lv2/blog"; //ブログ
@use "_lv2/aside"; //サイドバー

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	overflow: hidden;
	padding-top: 80px;
	background: $white;
	@include header-up {
		padding-top: 100px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 125px;
	}

	&__inner {
		position: relative;
		height: 220px;
		z-index: z-index(module, part01);
		background: url(../img/lv2/lv2-bg_sp.jpg) center center/cover no-repeat;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			transform-origin: top right;
			width: 53%;
			height: 100%;
			background: rgba($main_c, 0.7);
			transform: skew(-15deg);
		}
		@include media-breakpoint-up(md) {
			height: 30vw;
			background: url(../img/lv2/lv2-bg_tb.jpg) center center/cover no-repeat;
		}
		@include media-breakpoint-up(lg) {
			height: 20vw;
		}
		@include media-breakpoint-up(xl) {
			height: 310px;
			background: url(../img/lv2/lv2-bg_pc.jpg) center center/cover no-repeat;
		}
		@include media-breakpoint-up(full) {
			height: floor-decimal(calc(310 / 1920), 4) * 100vw;
		}
	}
	& .l-cont {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		z-index: z-index(module, part02);
	}
}

.main-view {
	&__ttl {
		position: relative;
		width: 100%;
		color: $white;
		text-align: center;
		@include f-w(400);
		@include line-h(1.4);
		& > em {
			position: relative;
			display: block;
			font-style: normal;
			text-transform: capitalize;
			padding-bottom: 0.2em;
			margin-bottom: 0.2em;
			@include line-h(1);
			@include f-family(font02);
			@include l-sp(0.03em);
			@include f-s_xxs(1.75, 46);
			@include f-w(600);
			@include media-breakpoint-up(xl) {
				@include f-size(80);
			}
			&::before {
				position: absolute;
				display: block;
				bottom: 0;
				left: calc(50% - 0.5em);
				content: "";
				width: 1em;
				height: 2px;
				background: $white;
				@include radius(999px);
			}
		}
		& > span {
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(1, 14);
			@include f-w(800);
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
			&.is-404 {
				@include f-family(font02);
				@include f-w(500);
				@include f-s_xxs(2.5, 32);
				@include l-sp(0.02em);
				@include media-breakpoint-up(xl) {
					@include f-size(72);
				}
			}
			&.is-category {
				display: inline-block;
				margin-top: 0.1em;
				@include l-sp(0);
				@include f-w(700);
				@include f-s_xxs(1, 16);
				@include media-breakpoint-up(xl) {
					@include f-size(28);
				}
				span {
					display: block;
				}
				.is-term {
					margin-top: 1em;
					display: inline-block;
					background-color: $p-gray;
					color: $main_c;
					text-align: center;
					line-height: 1.4;
					padding: 0.4em 1.25em;
					font-size: 0.85em;
					@include l-sp(0.05em);
					@include radius(999px);
					@include f-w(600);
					@include media-breakpoint-up(xl) {
						@include f-size(16);
					}
				}
			}
			&.is-archive {
				margin-top: 0.3em;
				@include f-w(700);
				@include f-s_xxs(1.25, 20);
				@include media-breakpoint-up(xl) {
					@include f-size(40);
				}
			}
		}
	}
}

// シングルページ用
.main-view {
	&__txt {
		color: $white;
		row-gap: .75em;
		text-align: center;
		@include m-a;
		@include flex-column;
		&__sub {
			position: relative;
			width: 100%;
			padding-bottom: 0.75em;
			@include f-w(400);
			@include line-h(1.4);
			&::after {
				position: absolute;
				bottom: 0;
				left: calc(50% - 1.5em);
				content: "";
				width: 3em;
				height: 2px;
				background: $white;
			}
			em {
				display: block;
				font-style: normal;
				@include f-family(font02);
				@include f-s_xxs(1.25, 20);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(48);
				}
			}
			span {
				display: block;
				padding-left: 0.2em;
				@include f-s_xxs(0.9, 6);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(20);
				}
			}
		}
		&__ttl {
			position: relative;
			width: 100%;
			@include f-w(700);
			@include line-h(1.4);
			@include f-s_xxs(1.14, 16);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
		&__category {
			li {
				display: inline-block;
				margin-right: 0.2em;
			}
			.category-item {
				background-color: $p-gray;
				color: $main_c;
				text-align: center;
				line-height: 1.4;
				padding: 0.4em 1.25em;
				font-size: 0.85em;
				@include l-sp(0.05em);
				@include radius(999px);
				@include f-w(600);
				@include media-breakpoint-up(xl) {
					@include f-size(16);
				}
			}
		}
	}
}



// 一覧ページが4列並びの場合
.common-column4 {
	&__list {
		//display: grid;
		//grid-template-columns: repeat(2,48.5%);
		//column-gap: 3%;
		//row-gap: clamp(2rem,4vw,4fem);
		//@include media-breakpoint-up(md) {
		//	grid-template-columns: repeat(4,23.5%);
		//	column-gap: 2%;
		//	row-gap: clamp(2rem,4vw,4fem);
		//}
	}
}



// common-flex
$common-main-w-lg: 72%; //メイン横幅%
$common-aside-w-lg: 25%; //アサイド横幅%
$common-main-w-xl: 74.16%; //メイン横幅%
$common-aside-w-xl: 20.83%; //アサイド横幅%

.common-flex {
	width: 100%;
	position: relative;
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		row-gap: clamp(3rem, 5vw, 5rem);
	}
	&.is-right {
		@include media-breakpoint-up(lg) {
			flex-direction: row-reverse;
		}
	}
	&__main {
		@include media-breakpoint-up(lg) {
			width: $common-main-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-main-w-xl;
		}
	}
	&__aside {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: $common-aside-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-aside-w-xl;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.common-flex__list {
	display: flex;
	&.is-col1 {
		flex-direction: column;
		row-gap: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(sm) {
			row-gap: clamp(1.5rem, 2vw, 2rem);
		}
	}
	&.is-col2 {
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			column-gap: 3%;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		@include media-breakpoint-up(md) {
			column-gap: 2%;
		}
		@include media-breakpoint-up(lg) {
			column-gap: 4.5%;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(sm) {
				flex-basis: 48.5%;
			}
			@include media-breakpoint-up(lg) {
				flex-basis: 47.75%;
			}
		}
	}
	&.is-col3 {
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			column-gap: 3%;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		@include media-breakpoint-up(md) {
			column-gap: 2%;
		}
		@include media-breakpoint-up(lg) {
			column-gap: 1.25%;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 2.8%;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(1.5rem, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(sm) {
				flex-basis: 48.5%;
			}
			@include media-breakpoint-up(md) {
				flex-basis: 32%;
			}
			@include media-breakpoint-up(lg) {
				flex-basis: 32.5%;
			}
			@include media-breakpoint-up(xl) {
				flex-basis: 31.46%;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	404 page
/--------------------------------------------------------------------*/
.cont-404 {
	&__inner {
		background: $white;
		border: 1px solid $m-gray;
		padding: clamp(2rem, 4vw, 4rem) 5%;
	}
	&__ttl {
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		text-align: center;
		@include f-s_xxs(1.14, 6);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		span {
			display: inline-block;
			padding-left: 2em;
			position: relative;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
	&__txt {
		margin-bottom: 4rem;
		@include clearfix;
		@include media-breakpoint-up(md) {
			margin-bottom: 6rem;
		}
	}
	&__thumbnail {
		float: right;
		width: 50%;
		margin-left: 6%;
		margin-bottom: 3rem;
	}
	&__foot {
		padding-top: clamp(3rem, 5vw, 5rem);
	}
}
.single-cont__tag {
	margin-bottom: 1em;
	a {
		font-size: 0.94em;
		color: $txt_c;
		padding-left: 1.5rem;
		margin-right: 0.5rem;
		padding-bottom: 0.3em;
		position: relative;
		@include icon(tag, before);
		@include dec-none;
		@include transition;
		&::before {
			position: absolute;
			top: 0.5em;
			left: 0.2rem;
			font-size: 0.875em;
			color: $gray;
			@include dec-none;
			@include transition;
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 1px;
			background: $main_c;
			@include transition;
		}
		&:hover {
			color: $main_c;
			&::before {
				color: $main_c;
			}
			&::after {
				width: 100%;
			}
		}
	}
}

.addtoany_shortcode {
	padding-bottom: 1.5em;
}

.single-sub {
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
		display: flex;
		align-items: flex-start;
	}
}

.single-date {
	@include f-family(font01);
	line-height: 1.4;
	color: $gray;
	margin-right: 1rem;
}

/* align */
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.alignright {
	float: right;
	margin-left: 1.5em;
}
.alignleft {
	float: left;
	margin-right: 1.5em;
}
