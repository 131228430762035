@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	poverty
/--------------------------------------------------------------------*/
// poverty-intro
.poverty-intro {
	position: relative;
	padding-top: 48vw;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		padding-top: clamp(6rem, 14vw, 14rem);
	}
	&__img {
		position: absolute;
		top: 0;
		right: 0;
		width: 90%;
		@include media-breakpoint-up(md) {
			width: 85%;
		}
		picture {
			position: relative;
			aspect-ratio: 38 / 27;
			@include media-breakpoint-up(md) {
				aspect-ratio: 116 / 75;
			}
		}
	}
}
.poverty-intro {
	&__txt {
		position: relative;
		z-index: z-index(module, part02);
		padding-left: map-get($contmargin, sp);
		padding-right: map-get($contmargin, sp);
		&__inner {
			padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 5%, 4em);
			background: $white;
			@include media-breakpoint-up(md) {
				width: 65%;
				padding: clamp(2em, 5vw, 5rem) clamp(1.5em, 6%, 6em);
			}
			@include media-breakpoint-up(lg) {
			}
			@include media-breakpoint-up(xl) {
				padding: clamp(2em, 6vw, 6rem) clamp(1.5em, 7%, 8em) clamp(2em, 6vw, 6rem) clamp(1.5em, 6%, 8em);
				width: 60%;
				min-height: 600px;
			}
		}
		&__ttl {
			padding-bottom: 0.75em;
			color: $main_c;
			margin-bottom: 0.5em;
			border-bottom: 1px solid $main_c;
			@include fs_xxs(15, 44);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				@include fs_md(20, 44);
			}
			@include media-breakpoint-up(xxxl) {
				@include f-size(44);
			}
			strong {
				font-size: 1.5em;
				@include f-w(700);
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				@include fs_md(13, 22);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
			strong {
				color: $txt_c;
				@include fs_xxs(11, 20);
				@include line-h(1.4);
				@include f-w(700);
				@include media-breakpoint-up(md) {
					font-size: 2vw;
				}
				@include media-breakpoint-up(xl) {
					font-size: 1.3em;
				}
				&.is-big {
					@include fs_xxs(15, 30);
					@include media-breakpoint-up(md) {
						font-size: 3vw;
					}
					@include media-breakpoint-up(xl) {
						font-size: 1.75em;
					}
				}
			}
		}
	}
}

// poverty-expenditure
.poverty-expenditure {
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(6rem, 9vw, 9rem);
	&__inner {
		max-width: 1200px;
		@include m-a;
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1.5em 4%;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(4, 1fr);
			gap: 2em 2%;
		}
	}
	&__item {
		overflow: hidden;
		@include radius(50%);
		
	}
}

// poverty-text
.poverty-text {
	position: relative;
	padding-top: clamp(3rem, 9vw, 9rem);
	padding-bottom: clamp(9rem, 38vw, 26rem);
	background: $p-gray;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		padding-bottom: clamp(6rem, 9vw, 9rem);
	}
	.l-cont {
	}
	&__txt {
		position: relative;
		z-index: z-index(module,part02);
		@include f-s_xxs(1.14,20);
		@include f-w(700);
		@include media-breakpoint-up(md) {
			padding-left: 50%;
			font-size: 2.75vw;
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(44);
		}
	}
	&__add {
		position: absolute;
		bottom: 0;
		@include media-breakpoint-up(md) {
			left: 7%;
			width: 34.375%;
		}
		@include media-breakpoint-up(xxl) {
			width: 660px;
		}
		@include media-breakpoint-down(sm) {
			width: 55%;
			left: 55%;
		}
	}
}
