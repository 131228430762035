@use "../_mixins/break-points" as *;
@use "../base" as *;
@use "../_plugins/slick" as *; //slick

/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/
.news-archive__list {
	padding-top: clamp(2rem,3vw,3rem);
	row-gap: clamp(2rem, 4vw, 4rem);
	@include flex-column;
}

// news box
.news-box {
	position: relative;
	background: $white;
	text-align: left;
	a {
		display: block;
		position: relative;
		height: 100%;
		color: $txt_c;
		@include dec-none;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.2);
		}
	}
	&__inner {
		display: grid;

		@include media-breakpoint-up(sm) {
			column-gap: 5%;
			grid-template-columns: 40% 1fr;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 300px 1fr;
		}
		@include media-breakpoint-down(xs) {
			grid-template-columns: auto;
			grid-template-rows: auto 1fr;
		
		}
	}
	&__img {
		grid-row: 1;
		@include media-breakpoint-up(sm) {
			grid-column: 1 / 2;
		}
		&__inner {
			margin: 0;
			@include media-breakpoint-up(sm) {
				@include aspect-img;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
				height: 100%;
			}
		}
	}
	&__txt {
		grid-row: 2;
		padding-top: .75em;
		padding-bottom: .75em;
		@include media-breakpoint-up(sm) {
			padding-top: 1em;
			padding-bottom: 1em;
			grid-column: 2 / 3;
			grid-row: 1;
		}
		&__sub {
			display: flex;
			column-gap: 1em;
			margin-bottom: 1em;
			& .is-date {
				color: $main_c;
				font-size: 1.14em;
				@include f-w(500);
				@include l-sp(0);
				@include f-family(font02);
			}
			& .is-category {
				display: flex;
				flex-wrap: wrap;
				column-gap: 0.4em;
				row-gap: 0.2em;
			}
		}
		&__ttl {
			margin-bottom: 0.5rem;
			padding-bottom: 0.5em;
			border-bottom: 1px solid $m-gray;
			@include f-w(600);
			@include f-s_xxs(1, 4);
			@include media-breakpoint-up(lg) {
				@include f-size(20);
			}
		}
		&__txt {
			font-size: 0.94em;
			@include line-h(1.5);
		}
	}
}
//------------------------------------------------------------//
// single page
//------------------------------------------------------------//

.news-content {
	max-width: 1200px;
	@include m-a;
	@include media-breakpoint-up(lg) {
		font-size: 1.14em;
	}
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(1200 / 1920), 4) * 100vw;
	}
}

// news-pdf
.pdf-btn {
	max-width: 480px;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(480 / 1920), 4) * 100vw;
	}
	a {
		padding: 1.25em 4% 1.25em 4.5em;
		border: 1px solid $gray;
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		color: $txt_c;
		background: $white;
		font-size: 1.14em;
		@include transition;
		@include dec-none;
		@include f-w(700);
		@include icon(pdf);
		@include media-breakpoint-up(lg) {
			font-size: 1.25em;
		}
		&::before {
			position: absolute;
			left: 1em;
			top: 0.7em;
			font-size: 1.8em;
			color: $gray;
			@include transition;
		}
		&:hover {
			background: $hover_c;
			border-color: $hover_c;
			color: $white;
			&::before {
				color: $white;
			}
		}
	}
}

.news-pdf {
	margin-bottom: clamp(3rem, 9vw, 9rem);
	&__list {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			column-gap: 2%;
			row-gap: 1em;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 0.5em;
		}
		& li {
			a {
				padding: 1.25em 4% 1.25em 4.5em;
				@include transition;
				border: 1px solid $m-gray;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				@include line-h(1.4);
				color: $txt_c;
				@include dec-none;
				@include f-w(500);
				@include radius(999px);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.25em;
					top: 0.8em;
					font-size: 1.6em;
					color: $m-gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
				}
			}
		}
	}
}

/* news-table */
.news-table {
	margin-top: clamp(3rem, 9vw, 9rem);
	&__list {
		row-gap: clamp(2em, 4vw, 4em);
		@include flex-column;
	}
}
.news-table__box {
	&__inner {
		border: 1px solid $m-gray;
		padding: clamp(1.5em, 2vw, 2rem) 5% clamp(1em, 1.5vw, 2rem);
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 2.5vw, 3rem) 8% clamp(2em, 3vw, 3.5rem);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 3.5vw, 4rem) 10% clamp(2em, 3vw, 3.5rem);
		}
	}
	&__ttl {
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include f-w(700);
		@include f-s_xxs(1.25, 8);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
	&__table {
		margin-bottom: 2rem;
		width: 100%;
		@include line-h(1.5);
		& tr {
			border-bottom: 1px solid $m-gray;
			&:first-child {
				border-top: 1px solid $m-gray;
			}
		}
		& th {
			text-align: left;
			padding: 1rem 0;
			@include f-w(700);
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 0;
				width: 25%;
				min-width: 180px;
			}
			@include media-breakpoint-up(xl) {
				width: 225px;
			}
			@include media-breakpoint-down(md) {
				display: block;
				padding-bottom: 0;
			}
		}
		& td {
			@include media-breakpoint-up(lg) {
				padding: 1.25rem 1.25rem 1.25rem 0.5rem;
			}
			@include media-breakpoint-down(md) {
				padding: 1rem 0;
				display: block;
				padding-top: 0;
				padding-left: 0;
			}
		}
	}
}

.news-single__foot {
	margin-top: clamp(4em, 8vw, 8em);
}
