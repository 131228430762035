@use "../_mixins/break-points" as *;
@use "../base" as *;
@use "../_plugins/slick" as *; //slick

/*--------------------------------------------------------------------/
	voice
/--------------------------------------------------------------------*/

//------------------------------------------------------------//
// single page
//------------------------------------------------------------//
// voice-intro
.voice-intro {
	position: relative;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	&__inner {
		@include media-breakpoint-up(md) {
			padding-top: clamp(3em, 10vw, 6em);
			min-height: 34.21vw;
		}
		@include media-breakpoint-down(sm) {
			@include flex-c-reverse;
		}
	}
	&__txt {
		position: relative;
		background: $p-gray;
		z-index: z-index(module, part02);
		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: clamp(1em,2vw,2em);
		@include media-breakpoint-up(md) {
			margin-left: 5%;
			width: 56%;
			padding: clamp(3em, 4vw, 4em) clamp(2em, 5%, 4em) clamp(3em, 4vw, 4em) clamp(2em, 7%, 6em);
		}
		@include media-breakpoint-up(xl) {
			width: 50%;
			min-height: 420px;
			padding: clamp(3em, 5vw, 5em) clamp(2em, 4%, 5em) clamp(3em, 5vw, 5em) clamp(3em, 12%, 7em);
		}
		@include media-breakpoint-up(xxl) {
			width: 46.875%;
		}
		@include media-breakpoint-down(sm) {
			width: 90%;
			padding: clamp(1.5em,3.5vw,3.5em) clamp(1.5em,5%,2em) clamp(2em,4vw,4em);
			margin-top: -2em;
			@include m-a;
		}
		&__ttl {
			@include f-s_xxs(1.14,16);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25,14);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
		&__name {
			color: $main_c;
			@include line-h(1.5);
			@include f-w(700);
			@include f-s_xxs(0.94,6);
			@include media-breakpoint-up(md) {
				@include f-s_md(1,4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			right: 0;
			width: 51.35vw;
			aspect-ratio: 3 / 2;
			z-index: z-index(module, part01);
		}
	}
}

//voice-list
.voice-list {
	counter-reset: voice-num;
	row-gap: clamp(3rem, 6vw, 6rem);
	max-width: 1200px;
	@include m-a;
	@include flex-column;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(1200 / 1920), 4) * 100vw;
	}
}

.voice-item {
	&__ttl {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 1em;
		align-items: center;
		padding-bottom: 0.75em;
		margin-bottom: 0.75em;
		border-bottom: 1px solid $m-gray;
		@include f-w(700);
		@include f-s_xxs(1,6);
		@include media-breakpoint-up(lg) {
			font-size: 1.37em;
		}
		&::before {
			counter-increment: voice-num;
			content: counter(voice-num, decimal-leading-zero);
			justify-self: start;
			color: $main_c;
			font-size: 2.5em;
			@include line-h(1);
			@include l-sp(0);
			@include f-family(font02);
			@include f-w(600);
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			font-size: 1.14em;
		}
	}
}

.voice-single__foot {
	margin-top: clamp(4em, 8vw, 8em);
}
