@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	about
/--------------------------------------------------------------------*/
// about-intro
.about-intro {
	position: relative;
	padding-bottom: clamp(6rem, 9vw, 9rem);

	&__inner {
		position: relative;
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: flex-end;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__img {
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			right: 54%;
			width: 37%;
		}
		@include media-breakpoint-down(sm) {
			width: 90%;
			@include m-a;
		}
	}
	&__txt {
		display: block;
		position: relative;
		padding-top: clamp(6rem, 25vw, 20rem);
		padding-right: 5%;
		padding-left: 5%;
		padding-bottom: clamp(3rem, 6vw, 6rem);
		background: $bg_c3;
		z-index: z-index(module, part01);
		margin-top: -20vw;
		@include media-breakpoint-up(md) {
			margin-top: 3em;
			width: 60%;
			padding-right: 8%;
			padding-left: 12%;
			padding-top: clamp(3rem, 6vw, 6rem);
			padding-bottom: clamp(3rem, 6vw, 6rem);
		}
		&__inner {
			@include fs_xxs(12,36);
			@include line-h(1.5);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				font-size: 2vw;
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(36);
			}
			strong {
				font-size: 1.4em;
				padding-bottom: 0.2em;
				color: $main_c;
				border-bottom: 2px solid $main_c;
			}
		}
	}
}

//about-explanation
.about-explanation {
	position: relative;
	padding-top: clamp(6rem, 14vw, 14rem);
	padding-bottom: clamp(6rem, 9vw, 9rem);
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 45%;
		@include media-breakpoint-up(lg) {
			min-height: 750px;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__ttl {
		color: $white;
		text-align: center;
		margin-bottom: clamp(1.5em, 6vw, 3em);
		b {
			@include f-w(700);
			@include f-s_xxs(1.14, 32);
			@include media-breakpoint-up(xl) {
				@include f-size(50);
			}
		}
		em {
			display: block;
			font-style: normal;
			@include f-s_xxs(0.9, 8);
			@include f-w(600);
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
	}
	&__txt {
		position: relative;
		max-width: 1200px;
		padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(3em, 6vw, 6rem);
		background: $white;
		border: 1px solid $m-gray;
		@include m-a;
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 5vw, 5em) clamp(1.5em, 8%, 8em) clamp(3em, 6vw, 6rem);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 5vw, 5em) clamp(1.5em, 15%, 12em) clamp(3em, 6vw, 6rem);
		}
		&__img {
			width: 60%;
			margin-bottom: clamp(1.5rem, 3vw, 3rem);
			@include m-a;
		}
		&__txt {
			@include line-h(2);
			@include media-breakpoint-up(lg) {
				font-size: 1.14em;
			}
		}
	}
}

// about-greetings
.about-greetings {
	position: relative;
	padding-top: 48vw;
	padding-bottom: clamp(3rem, 6vw, 6rem);
	overflow: hidden;
	@include media-breakpoint-up(md) {
		padding-top: clamp(6rem, 10vw, 12rem);
	}
	&__img {
		position: absolute;
		top: 0;
		right: 0;
		width: 90%;
		@include media-breakpoint-up(md) {
			width: 61.45%;
			height: 100%;
		}
		figure {
			@include media-breakpoint-up(md) {
				height: 100%;
			}
		}
	}
}

.about-greetings {
	& .l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		position: relative;
		padding-top: clamp(2em, 3vw, 3em);
		padding-right: clamp(1.5em, 5%, 3em);
		padding-bottom: clamp(2em, 3vw, 3em);
		padding-left: clamp(1.5em, 5%, 3em);
		background: $white;
		@include media-breakpoint-up(md) {
			width: 90%;
			padding-top: clamp(3em, 5vw, 5em);
			padding-right: clamp(2em, 8%, 7em);
			padding-bottom: clamp(3em, 5vw, 5em);
			padding-left: 0;
		}
		@include media-breakpoint-up(lg) {
			width: 80%;
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			column-gap: 8%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 2em;
		}
		.about-greetings__txt {
			@include media-breakpoint-up(md) {
				flex-shrink: 1;
				width: 68%;
			}
		}
	}
	&__ttl {
		margin-bottom: clamp(2em, 3vw, 3em);
		em {
			position: relative;
			display: block;
			font-style: normal;
			text-transform: capitalize;
			color: $main_c;
			@include line-h(1.2);
			@include f-s_xxs(2.5, 40);
			@include f-w(600);
			@include f-family(font02);
			@include media-breakpoint-up(xl) {
				@include f-size(86);
			}
		}
		b {
			@include f-s_xxs(1.14, 12);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 95%;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
		&__txt {
			@include media-breakpoint-up(lg) {
				@include line-h(2);
			}
			p {
				&:not(:last-child) {
					margin-bottom: 2em;
				}
			}
		}
	}
	&__pic {
		@include media-breakpoint-up(md) {
			min-width: 180px;
			width: 24%;
		}
		@include media-breakpoint-down(sm) {
			max-width: 220px;
			@include m-a;
		}
		figcaption {
			margin-top: 1rem;
			font-size: 1em;
			text-align: center;
			@include f-w(700);
			@include m-a;
			@include media-breakpoint-up(xl) {
				font-size: 1em;
			}
		}
	}
}

.about-greetings__bg {
	position: absolute;
	content: "";
	display: block;
	z-index: z-index(module, part01);
	overflow: hidden;
	@include media-breakpoint-up(sm) {
		top: 0;
		right: 0;
		width: 60%;
		height: 100%;
	}
	@include media-breakpoint-up(lg) {
		width: 30%;
	}
	@include media-breakpoint-down(xs) {
		width: 100%;
		height: 40vw;
		left: 0;
		bottom: 0;
	}
	&__inner {
		position: relative;
		height: 100%;
		&::after {
			content: "";
			background: rgba($white, 0.4);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include media-breakpoint-between(sm, md) {
				background: rgba($white, 0.7);
			}
		}
		& > * {
			height: 100%;
		}
	}
}

// about-philosophy
.about-philosophy {
	padding-top: clamp(5rem, 10vw, 12rem);
	padding-bottom: clamp(6rem, 12vw, 12rem);
	margin-bottom: clamp(6rem, 12vw, 12rem);
	position: relative;
	overflow: hidden;
	.l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
}

.about-philosophy {
	&__ttl {
		@include media-breakpoint-up(lg) {
			flex-basis: 38%;
		}
		@include media-breakpoint-up(xl) {
			flex-basis: 31.25%;
		}
		&__inner {
			color: $white;
			em {
				font-style: normal;
				padding-right: clamp(3rem, 6vw, 6rem);
				position: relative;
				display: block;
				margin-bottom: 0.2em;
				@include f-family(font02);
				@include f-s_xxs(2.25, 24);
				@include f-w(400);
				@include line-h(1);
				@include media-breakpoint-up(xl) {
					@include f-size(60);
				}
			}
			b {
				display: block;
				@include f-family(font01);
				@include f-s_xxs(1, 12);
				@include media-breakpoint-up(xl) {
					@include f-size(28);
				}
			}
		}
	}
}

.about-philosophy {
	&__txt {
		@include line-h(2);
		@include media-breakpoint-up(lg) {
			flex-basis: 60%;
		}
		@include media-breakpoint-up(xl) {
			flex-basis: 62.32%;
		}
		&__inner {
			background: $white;
			padding: clamp(1.5rem, 4vw, 4rem) 5%;
			@include media-breakpoint-up(md) {
				padding: clamp(2rem, 4vw, 4rem) 8%;
			}
			@include media-breakpoint-up(xl) {
				padding: clamp(2rem, 5vw, 5rem) 10%;
			}
			.txt {
				@include f-s_xxs(0.94, 4);
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1, 2);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(18);
				}
				p {
					&:not(:last-child) {
						margin-bottom: 1.5em;
					}
				}
				strong {
					font-size: 1.14em;
				}
			}
		}
	}
}

.about-philosophy {
	&__bg {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part01);
		background: $main_c;
		&__inner {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: z-index(module, part02);
			@include bg_filter;
		}
	}
}

// about-table
.about-table {
	padding-top: clamp(3rem, 9vw, 9rem);
	margin-bottom: clamp(6rem, 9vw, 9rem);
	@include flex-column;
	row-gap: clamp(6rem, 9vw, 9rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__ttl {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
	}
	&__table {
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
	}
}

// about-articles
.about-articles {
	width: 100%;
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&__inner {
		width: 100%;
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__ttl {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
		.txt {
			margin-bottom: 1.5em;
		}
	}
	&__btn {
		a {
			padding: 1.25em 4% 1.25em 4.5em;
			border: 1px solid $sub_c;
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			color: $white;
			background: $sub_c;
			font-size: 1.14em;
			@include transition;
			@include dec-none;
			@include f-w(700);
			@include radius(999px);
			@include icon(pdf);
			@include media-breakpoint-up(lg) {
				font-size: 1.25em;
			}
			&::before {
				position: absolute;
				left: 1.2em;
				top: 0.7em;
				font-size: 1.8em;
				color: $white;
			}
			&:hover {
				background: $hover_c;
				border-color: $hover_c;
				color: $white;
			}
		}
	}
}

// about-reports
.about-reports {
	width: 100%;
	&__inner {
		width: 100%;
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__ttl {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
		.txt {
			margin-bottom: 1.5em;
		}
	}
	&__btn {
		a {
			padding: 1.25em 4% 1.25em 4.5em;
			border: 1px solid $sub_c;
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			color: $white;
			background: $sub_c;
			font-size: 1.14em;
			@include transition;
			@include dec-none;
			@include f-w(700);
			@include radius(999px);
			@include icon(pdf);
			@include media-breakpoint-up(lg) {
				font-size: 1.25em;
			}
			&::before {
				position: absolute;
				left: 1.2em;
				top: 0.7em;
				font-size: 1.8em;
				color: $white;
			}
			&:hover {
				background: $hover_c;
				border-color: $hover_c;
				color: $white;
			}
		}
	}
}
