@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding: clamp(1.5em,2vw,2em) map-get($contmargin, sp);
  @include media-breakpoint-up(md) {
    padding-left: map-get($contmargin, tb);
    padding-right: map-get($contmargin, tb);
  }
  @include media-breakpoint-up(xl) {
    padding-left: map-get($contmargin, pc);
    padding-right: map-get($contmargin, pc);
  }
  &__list {
    @include flex-wrap;
    font-size: 0.78em;
    @include media-breakpoint-up(md) {
      font-size: 0.85em;
    }
    & .item-home {
      font-size: 1.1em;
    }
    li {
      position: relative;
      color: $d-gray;
      &:not(:last-child) {
        padding-right: 1.5rem;
        @include icon(arrow1_right, after);
        &::after {
          position: absolute;
          right: 0.3rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      color: $gray;
      @include transition;
      vertical-align: top;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: $main_c;
      }
    }
  }
}
