@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	scholarship
/--------------------------------------------------------------------*/
.scholarship-catch {
	position: relative;
	padding-top: clamp(3rem, 9vw, 9rem);
	padding-bottom: clamp(3rem, 9vw, 9rem);
	overflow: hidden;
	background: url(../img/lv2/scholarship_intro_bg.jpg) center center/cover no-repeat;
	&::before {
		position: absolute;
		top: 0;
		right: 64%;
		content: "";
		width: 100%;
		height: 100%;
		background: $sub_c;
		transform-origin: top right;
		transform: skew(-30deg);
		@include media-breakpoint-up(md) {
			right: 70%;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		color: $white;
		padding-left: 5%;
		@include media-breakpoint-up(md) {
			padding-left: 10%;
		}
		p {
			@include line-h(1.4);
			@include fs_xxs(16, 80);
			@include f-w(900);
			@include media-breakpoint-up(xxl) {
				@include f-size(80);
			}
		}
	}
}

// scholarship-philosophy
.scholarship-philosophy {
	padding-top: clamp(4rem, 9vw, 9rem);
	padding-bottom: clamp(4rem, 9vw, 9rem);
	&__inner {
		display: grid;
		max-width: 1200px;
		grid-template-columns: 50% 50%;
		place-content: center;
		@include m-a;
		@include media-breakpoint-up(sm) {
			grid-template-columns: 45% 45%;
		}
		@include media-breakpoint-up(full) {
			max-width: 62.5vw;
		}
	}
}

.scholarship-philosophy {
	&__box {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		@include m-a;
		@include media-breakpoint-up(md) {
			padding: 2em;
		}
		@include media-breakpoint-down(sm) {
			padding-bottom: 1.5em;
			padding-bottom: 1.5em;
		}
		&:nth-of-type(odd) {
			align-items: flex-start;
			&::after {
				position: absolute;
				top: 0;
				left: 96%;
				content: "";
				width: 1px;
				height: 100%;
				background: $main_c;
				transform: rotate(15deg);
			}
		}
		&:nth-of-type(even) {
			align-items: flex-end;
		}
		&__inner {
			text-align: center;
		}
		&__ttl {
			position: relative;
			padding-bottom: 3vw;
			margin-bottom: clamp(1.5em, 2vw, 2em);
			@include media-breakpoint-up(xl) {
				padding-bottom: 1.5em;
			}
			&::before {
				position: absolute;
				left: calc(50% - 1.5em);
				bottom: 0;
				content: "";
				width: 3em;
				height: 3px;
				background: $main_c;
				@include radius(999px);
			}
			em {
				position: relative;
				display: block;
				font-style: normal;
				text-transform: capitalize;
				color: $main_c;
				@include line-h(1);
				@include f-s_xxs(1.14, 70);
				@include f-w(600);
				@include f-family(font02);
				@include media-breakpoint-up(xl) {
					@include f-size(90);
				}
			}
			b {
				@include f-s_xxs(0.8, 16);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(28);
				}
			}
		}
		&__txt {
			@include line-h(1.5);
			@include f-w(700);
			@include f-s_xxs(0.8, 20);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
}

// scholarship-system
.scholarship-system {
	position: relative;
	padding-top: clamp(6rem, 9vw, 9rem);
	padding-bottom: clamp(6rem, 9vw, 6rem);
	background: $sub_c;
	&__inner {
		max-width: 1200px;
		@include m-a;
	}
	.heading-ttl {
		color: $white;
	}
	&__list {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
			gap: 2em 2%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			row-gap: 1.5em;
			max-width: 480px;
			@include m-a;
		}
	}
}

.scholarship-system__box {
	padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 4em);
	background: $white;
	container: systembox / inline-size;
	&__ttl {
		padding: 0.4em 1.25em;
		width: fit-content;
		text-align: center;
		color: $white;
		background: $main_c;
		font-size: 1.25em;
		margin-bottom: 1em;
		@include line-h(1.2);
		@include m-a;
	}
	&__txt {
		font-size: 0.94em;
		.is-catch {
			text-align: center;
			font-size: 1.2em;
			@include line-h(1.4);
			@include f-w(700);
			strong {
				font-size: 1.3em;
			}
			@container systembox (width > 280px) {
				font-size: 1.4em;
			}
		}
		p {
			margin-top: 1.5em;
			width: fit-content;
			@include m-a;
			@include line-h(1.5);
			strong {
				font-size: 1.3em;
			}
		}
	}
}

.scholarship-text {
	position: relative;
	padding-top: clamp(6rem, 12vw, 12rem);
	padding-bottom: clamp(12rem, 42vw, 30rem);
	background: $bg_c3;
	@include media-breakpoint-up(md) {
		padding-top: clamp(4rem, 9vw, 9rem);
		padding-bottom: clamp(6rem, 12vw, 12rem);
	}
	&__txt {
		max-width: 1200px;
		font-size: 1.07em;
		@include f-w(700);
		@include m-a;
		@include media-breakpoint-up(md) {
			font-size: 1.25em;
			padding-left: 13%;
		}
		@include media-breakpoint-up(lg) {
			padding-left: 0;
		}
		.txt {
			@include media-breakpoint-up(md) {
				width: 75%;
			}
		}
	}
	&__add01 {
		position: absolute;
		top: -15vw;
		left: 4%;
		width: clamp(70px,16%,110px);
		@include media-breakpoint-up(md) {
			width: 11%;
			top: -3vw;
			left: 3%;
		}
		@include media-breakpoint-up(lg) {
			top: -3em;
			left: auto;
			right: calc(50% + 680px);
		}
	}
	&__add02 {
		position: absolute;
		bottom: 0;
		right: 4%;
		width: 36%;
		@include media-breakpoint-up(md) {
			width: 21.25%;
		}
		@include media-breakpoint-up(lg) {
			right: 8%;
		}
	}
}
