@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/
.contact-intro {
	position: relative;
	padding-top: clamp(3rem, 6vw, 6rem);
	margin-bottom: clamp(2rem, 6vw, 6rem);
	&__txt {
		@include f-w(600);
		@include media-breakpoint-up(md) {
			text-align: center;
			font-size: 1.14em;
		}
	}
}

/*-------------------------------------------------
	contact form
-------------------------------------------------*/
/* variablea */
$radio_height: 26px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスのアニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスのアニメーションのスピード

.contact-form {
	&__ttl {
		color: $main_c;
		text-align: center;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include f-family(font02);
		@include f-w(500);
		@include f-s_xxs(1.25, 10);
		@include l-sp(0);
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
	}
}

.contact-mail {
	border: 1px solid $gray;
	padding: 1.5rem 6%;
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5rem, 3vw, 3rem) 10% clamp(2rem, 3.5vw, 3rem);
	}
	&__ttl {
		position: relative;
		@include f-s_xxs(1.14, 6);
		@include f-w(700);
		text-align: center;
		padding-bottom: 0.75em;
		margin-bottom: clamp(1rem, 2vw, 2rem);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			content: "";
			position: absolute;
			width: 4rem;
			height: 3px;
			bottom: 0;
			left: calc(50% - 2rem);
			border-radius: 2px;
			background: $main_c;
		}
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
		margin-top: 1.5rem;
		overflow-y: scroll;
		max-height: 300px;
		@include scroll-bar;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			padding: 0 5%;
		}
	}
	&__ttl {
		text-align: center;
		padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
		position: relative;
		@include f-s_xs(1.14, 6);
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			margin-bottom: clamp(1rem, 2vw, 2rem);
			&::before {
				content: "";
				position: absolute;
				width: 4rem;
				height: 3px;
				bottom: 0;
				left: calc(50% - 2rem);
				border-radius: 2px;
				background: $main_c;
			}
			.privacy-btn {
				display: none;
			}
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			position: relative;
			.privacy-btn {
				display: block;
				cursor: pointer;
				position: absolute;
				top: calc(50% - 16px);
				right: 1rem;
				width: 32px;
				height: 32px;
				@include transition;
				i {
					@include f-size(32);
					@include line-h(1);
					color: $gray;
				}
				&.is-active {
					transform: rotate(180deg);
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-down(md) {
			padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
		}
	}
	.txt {
		font-size: 0.94em;
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	margin-bottom: 2rem;
	line-height: 1.2;
	position: relative;
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(600);
		@include media-breakpoint-up(lg) {
			width: 30%;
			line-height: 1.5;
		}
		& span {
			color: $white;
			margin-left: 0.3rem;
			padding: 0.2em 0.75em;
			font-size: 0.8125em;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		@include media-breakpoint-down(md) {
			padding: 0.5em 0 2em;
		}
	}
	& textarea {
		width: 100%;
	}
}

.contact-form__certification {
	margin-top: 2em;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	column-gap: 2em;
	row-gap: 0.5em;
	dt {
		font-size: 1.07em;
		@include f-w(700);
		& span {
			color: $white;
			margin-left: 0.3rem;
			padding: 0.2em 0.75em;
			font-size: 0.8125em;
		}
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 70%;
		}
	}
	&.short {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 10em;
	}
}

[type="button"],
[type="submit"] {
	-webkit-appearance: none;
	font-family: inherit;
}

.form-btn {
	input {
		margin: 0;
		padding: 0;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	.chack-btn {
		position: relative;
		@include icon(direction_arrow_right, after);
		&::before {
			position: absolute;
			top: 1.25em;
			right: 0.75em;
			content: "";
			width: 32px;
			height: 32px;
			background: $white;
			z-index: z-index(module, part01);
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 1.75em;
			right: 1.1em;
			color: $main_c;
			font-size: 1em;
			z-index: z-index(module, part02);
		}
		&:hover {
			&::after {
				color: $hover_c;
			}
			input,
			a {
				color: $white;
				background-color: $hover_c;
			}
		}
		input,
		a {
			display: block;
			color: $white;
			background-color: $main_c;
			padding: 1.25em 4%;
			width: 100%;
			@include btn_base;
			@include f-size(18);
			@include l-sp(0.1em);
			@include radius(999px);
		}
	}
	.back-btn {
		position: relative;
		@include icon(direction_arrow_right, after);
		&::before {
			position: absolute;
			top: 1.25em;
			right: 0.75em;
			content: "";
			width: 32px;
			height: 32px;
			background: $white;
			z-index: z-index(module, part01);
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 1.75em;
			right: 1.1em;
			color: $b-gray;
			font-size: 1em;
			z-index: z-index(module, part02);
		}
		&:hover {
			&::after {
				color: $hover_c;
			}
			input,
			a {
				color: $white;
				background-color: $hover_c;
			}
		}
		input,
		a {
			display: block;
			color: $white;
			background-color: $b-gray;
			border: 1px solid;
			padding: 1.25em 4%;
			width: 100%;
			@include btn_base;
			@include f-size(18);
			@include l-sp(0.1em);
			@include radius(999px);
		}
	}
	.sub-btn {
		position: relative;
		@include icon(direction_arrow_right, after);
		&::before {
			position: absolute;
			top: 1.25em;
			right: 0.75em;
			content: "";
			width: 32px;
			height: 32px;
			background: $white;
			z-index: z-index(module, part01);
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 1.75em;
			right: 1.1em;
			color: $main_c;
			font-size: 1em;
			z-index: z-index(module, part02);
		}
		&:hover {
			&::after {
				color: $hover_c;
			}
			input,
			a {
				color: $white;
				background-color: $hover_c;
			}
		}
		input,
		a {
			display: block;
			color: $white;
			background-color: $main_c;
			padding: 1.25em 4%;
			width: 100%;
			@include btn_base;
			@include f-size(18);
			@include l-sp(0.1em);
			@include radius(999px);
		}
	}
}

/* radio-btn */
.wpcf7-radio {
	@include flex-column;
	row-gap: 1em;
	.wpcf7-list-item {
		display: block;
		margin: 0 !important;
	}
	label {
		cursor: pointer;
	}
}

input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .wpcf7-list-item-label {
		vertical-align: top;
		font-size: 1.07em;
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: relative;
			top: -0.1em;
			margin-right: 1em;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .wpcf7-list-item-label {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .wpcf7-list-item-label {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .wpcf7-list-item-label {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .wpcf7-list-item-label {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}
.wpcf7-checkbox {
	@include flex-column;
	row-gap: 1em;
	.wpcf7-list-item {
		display: block;
		margin: 0 !important;
	}
	label {
		cursor: pointer;
	}
}

.check-box {
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: 0em;
			left: 0;
			display: block;
			transition: border-color ease calc($duration_checkbox/2);
			z-index: z-index(module, part01);
		}
	}
	.wpcf7-list-item-label {
		vertical-align: top;
		padding-left: calc(#{$checkbox_height} + 1rem);
		display: block;
		font-size: 1.07em;
		&::before,
		&::after {
			position: absolute;
			height: 0;
			width: $checkbox_height * 0.2;
			background-color: $main_c;
			display: inline-block;
			transform-origin: left top;
			content: "";
			transition: opacity ease 0.5;
			z-index: z-index(module, part02);
		}
		&::before {
			top: $checkbox_height * 0.86;
			left: $checkbox_height * 0.4;
			transform: rotate(-135deg);
		}
		&::after {
			top: $checkbox_height * 0.5;
			left: $checkbox_height * 0.03;
			transform: rotate(-45deg);
		}
	}
}

input[type="checkbox"]:checked + .wpcf7-list-item-label,
.wpcf7-list-item-label.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck calc($duration_checkbox/2) ease 0s forwards;
	}
	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.wpcf7-not-valid-tip {
	font-size: 0.875em !important;
	margin-top: 0.7em;
}

div.wpcf7 .wpcf7-spinner {
	display: none !important;
}

/* form-parts
----------------------------------------------------------------*/
%form-parts {
	background-color: $o-white;
	padding: 1em;
	border: 1px solid $gray;
	line-height: 1.5;
	&:focus {
		background-color: #fff;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}
textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}
