@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	benefits
/--------------------------------------------------------------------*/
// benefits-intro
.benefits-intro {
	position: relative;
	padding-bottom: clamp(3rem, 6vw, 6rem);
	& .l-cont_xxxl {
		position: relative;
		padding-bottom: clamp(3rem, 6vw, 6rem);
		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 100%;
			height: 60%;
			background: $main_c;
		}
	}
	&__inner {
		position: relative;
		max-width: 1200px;
		padding: clamp(1.5em, 6vw, 6em) clamp(1.5em, 5%, 3em);
		background: $white;
		z-index: z-index(module, part02);
		border: 1px solid $m-gray;
		@include media-breakpoint-up(md) {
			padding: clamp(1.5em, 6vw, 6em) clamp(3em, 8%, 6em);
		}
		@include m-a;
	}
	&__ttl {
		text-align: center;
		margin-bottom: clamp(1.5em, 2vw, 2em);
		@include f-w(700);
		@include f-s_xxs(1.14, 10);
		@include media-breakpoint-up(xl) {
			@include f-size(30);
		}
		strong {
			font-size: 1.37em;
			display: inline-block;
			color: $main_c;
			padding-bottom: 0.1em;
			border-bottom: 1px solid $main_c;
			@include f-w(700);
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			font-size: 1.14em;
		}
	}
}

.benefits-nav {
	display: grid;
	grid-template-columns: repeat(auto-fit, 48%);
	gap: 1em 2%;
	padding-bottom: clamp(5rem, 9vw, 9rem);
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(auto-fit, 23.5%);
	}
	li {
		a {
			row-gap: 0.5em;
			border-bottom: 3px solid $main_c;
			align-items: center;
			@include flex-column;
			@include dec-none;
			@include transition;
			&:hover {
				border-color: $hover_c;
				span {
					color: $hover_c;
				}
				i {
					color: $hover_c;
				}
			}
			span {
				text-align: center;
				color: $txt_c;
				@include transition;
				@include line-h(1.2);
				@include f-w(700);
				@include media-breakpoint-up(md) {
					@include f-s_md(0.6, 5);
				}
				@include media-breakpoint-up(xl) {
					font-size: 1.25em;
				}
			}
			i {
				font-size: 3em;
				@include transition;
				@include line-h(1);
				@include media-breakpoint-up(md) {
					font-size: 3vw;
				}
				@include media-breakpoint-up(xl) {
					font-size: 3em;
				}
			}
		}
	}
}

// benefits-list
.benefits-list {
	row-gap: clamp(6rem, 9vw, 9rem);
	@include flex-column;
}

.benefits-box {
	position: relative;
	&__inner {
		position: relative;
		background: $bg_c3;
		padding: clamp(2em, 6vw, 4em) clamp(1.5em, 5%, 3em) clamp(2em, 2vw, 3em);
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 3vw, 3.5em) clamp(1.5em, 5%, 6em) clamp(2em, 4vw, 4em);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 3vw, 3.5em) clamp(3em, 8%, 6em) clamp(2em, 4vw, 4em);
		}
	}
	&__ttl {
		position: relative;
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: clamp(2em, 3vw, 3em);
		text-align: center;
		&::before {
			position: absolute;
			bottom: 0;
			left: calc(50% - 1.5em);
			content: "";
			width: 3em;
			height: 3px;
			background: $main_c;
			@include radius(999px);
		}
		span {
			position: absolute;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: clamp(60px, 20%, 98px);
		}
		b {
			display: block;
			@include f-s_xxs(1, 16);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
	&__toptxt {
		width: fit-content;
		margin-bottom: clamp(2em, 3vw, 3em);
		@include m-a;
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
	}
	&__bottomtxt {
		width: fit-content;
		@include m-a;
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
	}
}

.benefits-box__individual {
	&__grid {
		display: grid;
		margin-bottom: clamp(1.5em, 2vw, 2.5em);
		@include media-breakpoint-up(md) {
			grid-template-columns: 1fr 10% 1fr;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			row-gap: 1em;
		}
		.is-left {
			@include media-breakpoint-up(md) {
				grid-column: 1 / 2;
			}
		}
		.is-or {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.75em;
			@include line-h(1);
			@include f-family(font02);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				grid-column: 2 / 3;
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(34);
			}
		}
		.is-right {
			@include media-breakpoint-up(md) {
				grid-column: 3 / 4;
			}
		}
		.is-box {
			background: $white;
			padding: 1.5em 2em 2em;
			height: 100%;
			@include media-breakpoint-up(md) {
				padding: 1.5em 1em;
			}
			@include media-breakpoint-up(xl) {
				padding: 1.5em 2em 2em;
			}
			h3 {
				text-align: center;
				color: $main_c;
				margin-bottom: 0.5em;
				@include fs_xxs(14, 24);
				@include media-breakpoint-up(md) {
					@include fs_md(16, 28);
				}
			}
			.is-price {
				background: $p-gray;
				padding: 0.5em 1.25em;
				text-align: center;
				margin-bottom: 1em;
				@include f-w(700);
				@include line-h(1.5);
				@include media-breakpoint-up(md) {
					font-size: 1.5vw;
				}
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
				b {
					color: $main_c;
					font-size: 1.14em;
					@include f-w(600);
				}
			}
			ul {
				font-size: 0.9em;
				width: fit-content;
				@include m-a;
			}
		}
	}
	.is-text {
		margin-bottom: clamp(2em, 3vw, 3em);
		text-align: center;
		@include f-w(700);
		@include f-s_xxs(1, 8);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

.benefits-box__corporation {
	margin-bottom: clamp(2em, 3vw, 3em);
	row-gap: clamp(0.5rem, 1vw, 1rem);
	@include flex-column;
	.is-box {
		background: $white;
		height: 100%;
		padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(2em, 2vw, 3em);
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 3vw, 3.5em) clamp(1.5em, 5%, 6em) clamp(2em, 4vw, 4em);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 3vw, 3.5em) clamp(3em, 8%, 6em) clamp(2em, 4vw, 4em);
		}
		h3 {
			text-align: center;
			color: $main_c;
			margin-bottom: 1em;
			@include fs_xxs(12, 28);
		}
		.is-list {
			row-gap: clamp(1em, 2vw, 2em);
			@include flex-column;
		}
		.is-item {
			background: $p-gray;
			padding: 1.5em 1.25em;
			text-align: center;
			@include line-h(1.5);
			h4 {
				color: $sub_c;
				display: inline-block;
				padding: 0.5em 1em;
				background: $white;
				margin-bottom: 0.75em;
				@include f-w(700);
				@include fs_xxs(10, 24);
			}
			p {
				@include f-w(700);
				@include fs_xxs(9.5, 22);
			}
		}
		ul {
			margin-top: 1.5em;
			font-size: 0.9em;
			width: fit-content;
			@include m-a;
		}
	}
	.is-plus {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.5em;
		color: $txt_c;
		@include line-h(1);
		@include f-family(font02);
		@include f-w(700);
		@include media-breakpoint-up(xl) {
			@include f-size(90);
		}
	}
}
